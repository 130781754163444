import { Button } from "react-bootstrap";
import { generateLabel } from "../../../util";
import { toast } from "react-toastify";
import { PatientCase } from "../../../types/model";

export default function CopyLabel({ patientCase, practices, dentists }: { patientCase: PatientCase, practices: any, dentists: any}) {
  return (
    <td className="column label">
      <Button
        type="button"
        className="btn btn-primary m-1"
        title="Copy Label"
        onClick={() => {
          navigator.clipboard.writeText(
            generateLabel(
              patientCase,
              practices[patientCase.deliveryPracticeId]?.abr,
              dentists[patientCase.dentistId]?.lastName,
              dentists[patientCase.dentistId]?.status,
              patientCase.shade
            )
          );
          toast("Label copied to clipboard", {
            type: "success",
            theme: "colored",
            autoClose: 3000,
          });
        }}
      >
        &#128203;
      </Button>
    </td>
  )
}