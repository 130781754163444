import { FormikValues } from "formik";
import apiClient from "./api-client";

export function createPrice(values: FormikValues) {
  return apiClient.post("/price", values);
}

export function updateMillPrices(values: FormikValues) {
  return apiClient.put("/price/mill", values);
}

export function getMillPrices(id?: number) {
  return apiClient.get("/price" + (id ? "" : "s") + "/mill" + (id ? `?id=${id}` : ""));
}

export function getPrices() {
  return apiClient.get("/prices");
}

export function destroyPrice(id: number) {
  return apiClient.delete(`/price?id=${id}`);
}

export function getPrice(id: number) {
  return apiClient.get(`/price?id=${id}`);
}

export function updatePrice(values: FormikValues) {
  return apiClient.put("/price", values);
}