import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MillPrice } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import * as formik from "formik";
import * as yup from "yup";
import { getMillPrices, updateMillPrices } from "../../../api/prices";

const EditMillPrice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialPrice, setInitialPrice] = useState<MillPrice>({
    id: id ? parseInt(id) : 0,
    multilayerFlag: false,
    price: 0,
  });

  const schema = yup.object({
    multilayerFlag: yup.boolean(),
    price: yup
      .number()
      .positive("Price must be positive")
      .integer("Price must be a whole number")
      .required("Required"),
  });
  const { Formik } = formik;

  useEffect(() => {
    if (!initialPrice.price) {
      getMillPrices(id as unknown as number).then((response) => {
        response.data.anteriorFlag = Boolean(response.data.anteriorFlag);
        response.data.multilayerFlag = Boolean(response.data.multilayerFlag);
        setInitialPrice(response.data);
      });
    }
  }, [id, initialPrice.price]);

  const handleOnSubmit = (values: formik.FormikValues) => {
    updateMillPrices(values)
      .then(() => {
        toast("Successfully updated mill price. Redirecting to price home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/prices");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error updating mill price: ", error);
        toast("error updating mill price: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Update Mill Price</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={initialPrice}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} className="row g-3">
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    type="checkbox"
                    id="multilayer-checkbox"
                    label="Multilayer"
                    name="multilayerFlag"
                    checked={values.multilayerFlag}
                    onChange={handleChange}
                  />
                  {touched.multilayerFlag && errors.multilayerFlag ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.multilayerFlag}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="price"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Price</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="number"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    isInvalid={!!errors.price}
                  />
                  {touched.price && errors.price ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.price}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Update Mill Price
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default EditMillPrice;
