import { Form, InputGroup } from "react-bootstrap";
import { PatientCase } from "../../../types/model";

export default function Status({ patientCase, patientCases, getClassesForStatus, setCases, forceUpdate, isBasicUser, BASIC_COMPLETION_STATUSES, COMPLETION_STATUSES, updateCompletionStatus  }: { patientCase: PatientCase, patientCases: PatientCase[], getClassesForStatus: (status: string) => string, setCases: (cases: PatientCase[]) => void, forceUpdate: () => void, isBasicUser: boolean, BASIC_COMPLETION_STATUSES: string[], COMPLETION_STATUSES: string[], updateCompletionStatus: (id: number, status: string) => void}) {
  return (
    <td className="column status">
      <InputGroup className="d-inline-flex m-1">
        <Form.Select
          className={getClassesForStatus(
            patientCase.completionStatus
          ) + " status-select"}
          value={patientCase.completionStatus}
          onChange={(event: { target: { value: any } }) => {
            const otherCases: PatientCase[] =
              structuredClone(patientCases);
            const index: number = otherCases.findIndex(
              (pc: PatientCase) => pc.id === patientCase.id
            );
            const c: PatientCase = otherCases.splice(index, 1)[0];
            c.completionStatus = event.target.value;
            otherCases.splice(index, 0, c);
            setCases(otherCases);
            forceUpdate();
            updateCompletionStatus(patientCase.id, event.target.value)
          }}
        >
          {(isBasicUser
            ? BASIC_COMPLETION_STATUSES
            : COMPLETION_STATUSES
          ).map((status) => {
            return <option key={status} value={status}>{status}</option>;
          })}
        </Form.Select>
      </InputGroup>
    </td>
  )
}