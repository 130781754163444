import { Tooltip } from 'react-tooltip'

import { Table } from "react-bootstrap";
import { Dentist, PatientCase, Practice } from "../../../types/model";
import { AxiosError } from "axios";
import {
  API_BASE_URL,
  BASIC_COMPLETION_STATUSES,
  COMPLETION_STATUSES,
} from "../../../consts";
import { useEffect, useState } from "react";
import useToken from "../../UseToken";
import { ToastContainer, toast } from "react-toastify";
import { useForceUpdate } from "../../ForceUpdate";
import {
  getClassesForStatus,
} from "../../../util";
import Legend from "../../legend";
import Received from "../../cases/columns/Received";
import Due from "../../cases/columns/Due";
import DentistCol from "../../cases/columns/Dentist";
import Patient from "../../cases/columns/Patient";
import Engraving from "../../cases/columns/Engraving";
import Units from "../../cases/columns/Units";
import Shade from "../../cases/columns/Shade";
import Adapter from "../../cases/columns/Adapter";
import Flag from "../../cases/columns/Flag";
import Adjust from "../../cases/columns/Adjust";
import Status from "../../cases/columns/Status";
import Delivery from "../../cases/columns/Delivery";
import Ship from "../../cases/columns/Ship";
import Tracking from "../../cases/columns/Tracking";
import Anterior from "../../cases/columns/Anterior";
import Posterior from "../../cases/columns/Posterior";
import Cost from "../../cases/columns/Cost";
import Notes from "../../cases/columns/Notes";
import CopyLabel from '../../cases/columns/CopyLabel';
import ColumnHeader from '../../table/ColumnHeader';
import { destroyCase, getActiveCases, updateCaseAdjustBite, updateCaseAdjustOpposing, updateCaseAdjustProximal, updateCaseNotes, updateCaseStatus, updateCaseTrackingNumber, updateCasesMilledToShipped, updateCasesShippedToBilled } from '../../../api/cases';
import { getDentists } from '../../../api/dentists';
import { getPractices } from '../../../api/practices';
import { savePatientCaseRedirect } from '../../../util/NavRedirects';

const ViewCases = () => {
  const [savingPatientId, setSavingPatientId] = useState<number | null>();
  const [patientCases, setCases] = useState<PatientCase[]>([]);
  const [sortName, setSortName] = useState<string>("received");
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>("desc");
  const { token } = useToken();
  const forceUpdate = useForceUpdate();
  const [fetchedCases, setFetchedCases] = useState<boolean>(false);
  const [fetchedDentists, setFetchedDentists] = useState<boolean>(false);
  const [dentists, setDentists] = useState<{
    [key: string]: { niceName: string; lastName: string; status: string };
  }>({});
  const [fetchedPractices, setFetchedPractices] = useState<boolean>(false);
  const [practices, setPractices] = useState<{
    [key: string]: { abr: string; name: string };
  }>({});
  const [newTrackingNums, setNewTrackingNums] = useState<{
    [id: string]: string;
  }>({});
  const [newNotes, setNewNotes] = useState<{
    [id: string]: string;
  }>({});


  // Save that we got here so we can redirect back if we need to after editing a patient case.
  useEffect(() => {
    savePatientCaseRedirect(window.location.pathname);
  });

  const getRecentCases = () => {
    getActiveCases()
      .then((response) => {
        setCases(response.data);
      })
      .catch((error: AxiosError) => {
        toast("error getting all cases: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const [isBasicUser, setIsBasicUser] = useState<boolean>(false);
  useEffect(() => {
    if (token) {
      setIsBasicUser(token.role === "Basic");
    }
  }, [token]);

  const updateCompletionStatus = (id: number, status: string) => {
    if (status === "Shipped") {
      const c = patientCases.filter((x) => x.id === id)[0];
      if (c.deliveryMethod !== "DEL" && !c.trackingNumber) {
        toast("Cannot set status to shipped without a tracking number", {
          type: "error",
          theme: "colored",
          autoClose: 3000,
        });
        return;
      }
    }
    updateCaseStatus(id, status)
      .then(() => {
        toast("Successfully updated case completion status", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });
        if (["Shipped", "Billed"].includes(status)) {
          setCases(
            patientCases.filter((x) => {
              return x.id !== id;
            })
          );
        }
      })
      .catch((error: AxiosError) => {
        toast("error updating completion status: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const updateTrackingNumber = (id: number) => {
    updateCaseTrackingNumber(id, newTrackingNums[id.toString()])
      .then(() => {
        toast("Successfully updated case tracking number", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });
        const copiedCases = structuredClone(patientCases);
        const index: number = copiedCases.findIndex(
          (pc: PatientCase) => pc.id === id
        );
        const updatedCase = copiedCases.splice(index, 1)[0];
        updatedCase.trackingNumber = newTrackingNums[id];
        copiedCases.splice(index, 0, updatedCase);
        setCases(copiedCases);
        forceUpdate();
      })
      .catch((error: AxiosError) => {
        toast("error updating tracking number: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const updateNotes = (id: number) => {
    
    updateCaseNotes(id, newNotes[id.toString()])
      .then(() => {
        toast("Successfully updated case notes", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });
      })
      .catch((error: AxiosError) => {
        console.warn("error updating notes: ", error);
        toast("error updating notes: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const updateAdjustOpposing = (id: number, adjOp: boolean) => {
    updateCaseAdjustOpposing(id, adjOp)
      .then(() => {
        toast("Successfully updated case adjust opposing", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });
      })
      .catch((error: AxiosError) => {
        toast("error updating opposing flag: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const updateAdjustProximal = (id: number, adjProx: boolean) => {
    updateCaseAdjustProximal(id, adjProx)
      .then(() => {
        toast("Successfully updated case adjust proximal", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });
      })
      .catch((error: AxiosError) => {
        toast("error updating proximal flag: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const updateAdjustBite = (id: number, bite: boolean) => {
    updateCaseAdjustBite(id, bite)
      .then(() => {
        toast("Successfully updated case adjust bite", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });
      })
      .catch((error: AxiosError) => {
        console.warn("error updating bite flag: ", error);
        toast("error updating bite flag: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    if (!fetchedCases) {
      setFetchedCases(true);
      getRecentCases();
    }
  }, [fetchedCases]);

  useEffect(() => {
    if (!fetchedDentists) {
      setFetchedDentists(true);
      getAllDentists();
    }
  }, [fetchedDentists]);

  const getAllDentists = () => {
    getDentists()
      .then((response) => {
        const dentistMap: {
          [key: string]: { niceName: string; lastName: string; status: string };
        } = {};
        response.data.forEach((dentist: Dentist) => {
          dentistMap[dentist.id.toString()] = {
            niceName:
              (dentist.docFirst
                ? dentist.docFirst.substring(0, 1) + ". "
                : "") + dentist.docName,
            lastName: dentist.docName,
            status: dentist.status,
          };
        });
        setDentists(dentistMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all dentists: ", error);
        toast("error getting all dentists: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const deleteCase = (id: string) => {
    destroyCase(id)
      .then(() => {
        toast("Successfully deleted case", {
          type: "success",
          theme: "colored",
        });
        setCases(
          patientCases.filter((c) => {
            return c.id !== parseInt(id);
          })
        );
      });
  };

  useEffect(() => {
    if (!fetchedPractices) {
      setFetchedPractices(true);
      getAllPractices();
    }
  }, [fetchedPractices]);

  // Set auto sizing for textarea
  useEffect(() => {
    const textAreas = document.querySelectorAll("textarea");
    textAreas.forEach((textArea) => {
      textArea.style.height = "1px";
      textArea.style.height = Math.min(textArea.scrollHeight, 75) + "px";
    });
  });

  const getAllPractices = () => {
    getPractices()
      .then((response) => {
        const practiceMap: { [key: string]: { abr: string; name: string } } =
          {};
        response.data.forEach((practice: Practice) => {
          practiceMap[practice.id.toString()] = {
            abr: practice.abbreviation,
            name: practice.name,
          };
        });
        setPractices(practiceMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const setMilledToShipped = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    updateCasesMilledToShipped()
      .then(() => {
        toast("Cases updated", {
          type: "success",
          theme: "colored",
          autoClose: 3000,
        });

        getRecentCases();
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  }

  const handleSortClick = (name: string) => {
    if (name === sortName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortName(name);
      setSortDirection("asc");
    }
  };

  const handleSort = (a: PatientCase, b: PatientCase) =>  {
    if (sortName === "received") {
      return sortDirection === "asc"
        ? new Date(a.receivedTime).getTime() - new Date(b.receivedTime).getTime()
        : new Date(b.receivedTime).getTime() - new Date(a.receivedTime).getTime();
    } else if (sortName === "dentist") {
      return sortDirection === "asc"
        ? dentists[a.dentistId].niceName.localeCompare(
            dentists[b.dentistId].niceName
          )
        : dentists[b.dentistId].niceName.localeCompare(
            dentists[a.dentistId].niceName
          );
    } else if (sortName === "patient") {
      return sortDirection === "asc"
        ? a.patientName.localeCompare(b.patientName)
        : b.patientName.localeCompare(a.patientName);
    } else if (sortName === 'delivery') {
      return sortDirection === "asc"
        ? practices[a.deliveryPracticeId]?.abr?.localeCompare(practices[b.deliveryPracticeId]?.abr)
        : practices[b.deliveryPracticeId]?.abr?.localeCompare(practices[a.deliveryPracticeId]?.abr);
    }
  };

  const tableStatuses = [...new Set(patientCases?.map(patientCase => patientCase.completionStatus))];

  return (
    <div className={`font-size-small role-${token?.role?.toLowerCase()?.replace(' ', '-')}`}>
      <div className="page-actions">
        <Legend items={tableStatuses.map((status) => {
          return {
            label: status,
            color: getClassesForStatus(status),
            value: patientCases.filter(patientCase => patientCase.completionStatus === status).reduce((acc, patientCase) => acc + patientCase.posterior + patientCase.anterior, 0)
          }
        })}/>
        {token && token.role === "Admin" && (
          <div>
            <button
              className="btn btn-secondary btn-sm my-2 float-end"
              type="button"
              onClick={() => {
                updateCasesShippedToBilled()
                  .then(() => {
                    toast("Cases updated", {
                      type: "success",
                      theme: "colored",
                      autoClose: 3000,
                    });
                  })
                  .catch((error: AxiosError) => {
                    console.warn("error getting all practices: ", error);
                    toast("error getting all practices: " + error.message, {
                      type: "error",
                      theme: "colored",
                      autoClose: 1500,
                    });
                  });
              }}
            >
              Set Shipped to Billed
            </button>
          </div>
        )}
        {token && token.role === "Admin" && (
          <div>
            <button
              className="btn btn-orange btn-sm my-2 float-end"
              type="button"
              onClick={setMilledToShipped}
            >
              Set Milled to Shipped
            </button>
          </div>
        )}
        {token && token.role === "Admin" && (
          <div>
            <button
              className="btn btn-info btn-sm my-2 float-end"
              type="button"
              onClick={() => {
                window.open(API_BASE_URL + "/download/billing");
              }}
            >
              Download Billing
            </button>
          </div>
        )}
        <div>
          <button
            className="btn btn-warning btn-sm my-2 float-end"
            type="button"
            onClick={() => {
              window.open(API_BASE_URL + "/download/labels");
            }}
          >
            Download Labels
          </button>
        </div>
        <div >
          <a href={"/cases/filter"} className="btn btn-primary btn-sm my-2 float-end">
            Filter Cases
          </a>
        </div>
        {token && (token.role === "Admin" || token.role === "Case Manager") && (
          <div>
            <a href={"/case/create"} className="btn btn-success btn-sm my-2 float-end">
              Create Case
            </a>
          </div>
        )}
      </div>

      <Tooltip id="tooltip" />
      
      <div className="table-container">
        <Table striped hover className="less-pad cases-table">
          <thead>
            <tr>
              <ColumnHeader 
                sortable={true}
                name="received"
                width="80px"
                sortName={sortName}
                onSort={handleSortClick}
                sortDirection={sortDirection}
              >
                RECVD
              </ColumnHeader>
              <ColumnHeader name="due">Due</ColumnHeader>
              <ColumnHeader 
                sortable={true} 
                name="dentist" 
                sortName={sortName}
                onSort={handleSortClick} 
                sortDirection={sortDirection}
              >
                Dentist
              </ColumnHeader>
              <ColumnHeader 
                sortable={true}
                name="patient"
                sortName={sortName}
                onSort={handleSortClick}
                sortDirection={sortDirection}
              >
                Patient
              </ColumnHeader>
              <ColumnHeader name="engraving">ENG</ColumnHeader>
              <ColumnHeader name="number">#</ColumnHeader>
              <ColumnHeader name="shade">S</ColumnHeader>
              <ColumnHeader name="adp">ADP</ColumnHeader>
              <ColumnHeader name="o">O</ColumnHeader>
              <ColumnHeader name="adjust">ADJ</ColumnHeader>
              <ColumnHeader name="status">Status</ColumnHeader>
              <ColumnHeader 
                sortable={true} 
                onSort={handleSortClick} 
                sortDirection={sortDirection} 
                name="delivery">DEL</ColumnHeader>
              <ColumnHeader name="ship">Ship</ColumnHeader>
              <ColumnHeader name="tracking">Tracking</ColumnHeader>
              <ColumnHeader name="ant">Ant</ColumnHeader>
              <ColumnHeader name="post">Post</ColumnHeader>
              {token && (token.role === "Admin" || token.role === "Case Manager") && (
                <ColumnHeader name="cost">Cost</ColumnHeader>
              )}
              <ColumnHeader name="notes">Notes</ColumnHeader>
              <ColumnHeader name="label">Label</ColumnHeader>
              {token && (token.role === "Admin" || token.role === "Case Manager") && (
                <ColumnHeader name="actions">Actions</ColumnHeader>
              )}
            </tr>
          </thead>
          <tbody>
            {patientCases &&
              patientCases?.sort((a, b) => handleSort(a, b) || 0)?.map((patientCase) => {
                return (
                  <tr key={patientCase.id}>
                    <Received patientCase={patientCase} />
                    <Due patientCase={patientCase} />
                    <DentistCol patientCase={patientCase} dentists={dentists} />
                    <Patient patientCase={patientCase} />
                    <Engraving allCases={patientCases} patientCase={patientCase} />
                    <Units patientCase={patientCase} />
                    <Shade patientCase={patientCase} />
                    <Adapter patientCase={patientCase} />
                    <Flag patientCase={patientCase} />
                    <Adjust 
                      patientCase={patientCase} 
                      patientCases={patientCases} 
                      setCases={setCases} 
                      forceUpdate={forceUpdate} 
                      updateAdjustOpposing={updateAdjustOpposing} 
                      updateAdjustProximal={updateAdjustProximal} 
                      updateAdjustBite={updateAdjustBite} 
                    />
                    <Status 
                      patientCase={patientCase} 
                      patientCases={patientCases} 
                      getClassesForStatus={getClassesForStatus} 
                      setCases={setCases} 
                      forceUpdate={forceUpdate} 
                      isBasicUser={isBasicUser} 
                      BASIC_COMPLETION_STATUSES={BASIC_COMPLETION_STATUSES} 
                      COMPLETION_STATUSES={COMPLETION_STATUSES} 
                      updateCompletionStatus={updateCompletionStatus}
                    />
                    <Delivery patientCase={patientCase} practices={practices} />
                    <Ship patientCase={patientCase} />
                    <Tracking 
                      patientCase={patientCase} 
                      newTrackingNums={newTrackingNums} 
                      setNewTrackingNums={setNewTrackingNums} 
                      updateTrackingNumber={updateTrackingNumber}
                    />
                    <Anterior patientCase={patientCase} />
                    <Posterior patientCase={patientCase} />
                    {token && (token.role === "Admin" ||
                      token.role === "Case Manager") && (
                      <Cost patientCase={patientCase} />
                    )}
                    <Notes 
                      patientCase={patientCase} 
                      newNotes={newNotes} 
                      setNewNotes={setNewNotes} 
                      updateNotes={updateNotes}
                      setSavingPatientId={setSavingPatientId}
                    />
                    <CopyLabel 
                      patientCase={patientCase} 
                      practices={practices} 
                      dentists={dentists}
                    />
                    
                    {token &&
                      (token.role === "Admin" ||
                        token.role === "Case Manager") && savingPatientId !== patientCase.id && (
                        <td>
                          <a
                              title="Edit Case"
                              href={"/case/" + patientCase.id + "/edit"}
                              className="btn btn-primary m-1"
                            >
                              <span className="edit-icon">&#9998;</span>
                            </a>
                            {token && token.role === "Admin" && (
                              <button
                                type="button"
                                title="Delete Case"
                                onClick={() =>
                                  deleteCase(patientCase.id.toString())
                                }
                                className="btn btn-danger m-1"
                              >
                                X
                              </button>
                            )}
                        </td>
                      )}

                    { savingPatientId === patientCase.id && (
                      <td>
                        <div className="inline-saving">
                          Saving...
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ViewCases;
