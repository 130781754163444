import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { AxiosError } from "axios";
import { Dentist, Practice } from "../../../types/model";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import { PracticeOption } from "../../../types/utility";
import { ToastContainer, toast } from "react-toastify";
import { getDentist } from "../../../api/dentists";
import { assignDentistToPractice, getPractices } from "../../../api/practices";

const AddPracticeToDentist = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dentist, setDentist] = useState<Dentist>({
    id: id ? parseInt(id) : 0,
    status: "",
    docName: "",
    assistName: "",
    docPhone: "",
    assistPhone: "",
    docEmail: "",
    notes: "",
  });
  const [practices, setPractices] = useState<PracticeOption[]>([]);
  const [selectedPractices, setSelectedPractices] = useState<PracticeOption[]>(
    []
  );

  useEffect(() => {
    if (!dentist.docName && id) {
      getDentist(id as unknown as number)
        .then((response) => {
          setDentist(response.data);
        })
        .catch((error: AxiosError) => {
          console.warn("error fetching dentist: ", error);
          toast("error fetching dentist: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
    if (!practices.length) {
      getPractices().then((response) => {
        const practiceOptions = response.data.map((practice: Practice) => {
          return {
            id: practice.id,
            name: practice.name,
          };
        });
        setPractices(practiceOptions);
      });
    }
  }, []);

  const handleOnSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!selectedPractices.length) {
      toast("Please select a practice to add to this dentist", {
        type: "error",
        theme: "colored",
      });
    } else {
      assignDentistToPractice(selectedPractices[0].id, id as unknown as number)
        .then(() => {
          toast(
            "Successfully added practice to dentist. Redirecting to dentist home",
            {
              type: "success",
              theme: "colored",
              autoClose: 1500,
            }
          );
          setTimeout(() => {
            navigate("/dentists");
          }, 1500);
        })
        .catch((error: AxiosError) => {
          console.warn("error adding dentists to practice: ", error);
          toast("error adding dentists to practice: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  };

  const handleInputChange = (selected: any) => {
    setSelectedPractices(selected);
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">
          Add{" "}
          {(dentist.docFirst ? dentist.docFirst + " " : "") + dentist.docName}{" "}
          to Practice
        </h1>
        <Form onSubmit={handleOnSubmit} className="row g-3">
          <Form.Group
            className="mt-3 col-md-6 offset-md-3"
            controlId="selectedDentists"
          >
            <Form.Label>Select Practice</Form.Label>
            <Typeahead
              id="select-practice-dropdown"
              onChange={handleInputChange}
              options={practices}
              placeholder="Choose a practice"
              selected={selectedPractices}
              labelKey="name"
            />
          </Form.Group>
          <Form.Group className="mt-3 col-md-6 offset-md-3">
            <Button
              variant="primary"
              type="submit"
              className="submit-btn float-end"
            >
              Add to Practice
            </Button>
          </Form.Group>
        </Form>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default AddPracticeToDentist;
