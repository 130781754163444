import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as formik from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Practice } from "../../../types/model";
import { PracticeOption } from "../../../types/utility";
import { Typeahead } from "react-bootstrap-typeahead";
import { createUser, getUserRoles } from "../../../api/users";
import { getPractices } from "../../../api/practices";

const CreateUser = () => {
  const schema = yup.object({
    id: yup.string().required("Required"),
    password: yup.string().required("Required"),
    role: yup.string().required("Required"),
    selectedPractice: yup.array(
      yup.object({
        id: yup.number(),
        name: yup.string(),
      })
    ),
  });
  const { Formik } = formik;
  const [roles, setRoles] = useState<string[]>([]);
  const navigate = useNavigate();
  const [practices, setPractices] = useState<PracticeOption[]>([]);
  const [fetchedPractices, setFetchedPractices] = useState<boolean>(false);
  const [selectedPractice, setSelectedPractice] = useState<PracticeOption[]>(
    []
  );

  useEffect(() => {
    if (!roles.length) {
      getUserRoles()
        .then((response) => {
          setRoles(response.data);
        })
        .catch((error: AxiosError) => {
          console.warn("error fetching roles: ", error);
          toast("error fetching roles: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  });

  useEffect(() => {
    if (!fetchedPractices) {
      getAllPractices();
      setFetchedPractices(true);
    }
  }, [fetchedPractices]);

  const handleOnSubmit = (values: formik.FormikValues) => {
    if (values.role === "Practice" && !values.selectedPractice.length) {
      toast("You must select a practice to create a practice user", {
        type: "error",
        theme: "colored",
        autoClose: 3000,
      });
      return;
    }
    if (values.role === "Practice" && values.selectedPractice.length) {
      values.practice = values.selectedPractice[0].id;
    }
    createUser(values)
      .then(() => {
        toast("Successfully created user. Redirecting to user home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/users");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error creating user: ", error);
        toast("error creating user: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const getAllPractices = () => {
    getPractices().then((response) => {
      const practiceOptions = response.data.map((practice: Practice) => {
        return {
          id: practice.id,
          name: practice.name,
        };
      });
      if (practiceOptions.length > 0) {
        setPractices(practiceOptions);
      }
    });
  };

  return (
    <div className="main-form container">
      <h1 className="mt-3 col-md-6 offset-md-3">Create User</h1>
      <Formik
        validationSchema={schema}
        onSubmit={handleOnSubmit}
        initialValues={{
          id: "",
          password: "",
          role: "Basic",
          practice: 0,
          selectedPractice,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form
            noValidate
            onSubmit={handleSubmit}
            className="row g-3"
            autoComplete="off"
          >
            <Form.Group controlId="id" className="mt-3 col-md-6 offset-md-3">
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className="input-control"
                  type="text"
                  name="id"
                  value={values.id}
                  onChange={handleChange}
                  isInvalid={!!errors.id}
                  autoComplete="off"
                />
                {touched.id && errors.id ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.id}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="password"
              className="mt-3 col-md-6 offset-md-3"
            >
              <Form.Label>Password</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className="input-control"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                  autoComplete="new-password"
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="role" className="mt-3 col-md-6 offset-md-3">
              <Form.Label>Role</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  aria-label="Default select example"
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  isInvalid={!!errors.role}
                >
                  {roles &&
                    roles.map((role) => {
                      return <option value={role}>{role}</option>;
                    })}
                </Form.Select>
                {touched.role && errors.role ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.role}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
            {values.role === "Practice" && (
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedPractice"
              >
                <Form.Label>Select Practice</Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-practice-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedPractice", selected);
                      setSelectedPractice(selected as PracticeOption[]);
                    }}
                    options={practices}
                    placeholder="Choose a practice"
                    labelKey="name"
                    isInvalid={!!errors.selectedPractice}
                    selected={values.selectedPractice}
                    disabled={!practices.length}
                    clearButton={true}
                  />
                  {touched.selectedPractice && errors.selectedPractice ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedPractice}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
            )}
            <Form.Group className="mt-3 col-md-6 offset-md-3">
              <Button
                variant="primary"
                type="submit"
                className="submit-btn float-end"
              >
                Create User
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default CreateUser;
