import { Table } from "react-bootstrap";
import { User } from "../../../types/model";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import useToken from "../../UseToken";
import { ToastContainer, toast } from "react-toastify";
import { destroyUser, getUsers } from "../../../api/users";

const ViewUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { token } = useToken();

  const getAllUsers = () => {
    getUsers()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all users: ", error);
        toast("error getting all users: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const deleteUser = (id: string) => {
    destroyUser(id as unknown as number)
      .then(() => {
        toast("Successfully deleted user", {
          type: "success",
          theme: "colored",
        });
        setUsers(
          users.filter((user) => {
            return user.id !== id;
          })
        );
      });
  };

  return (
    <div className="container">
      {token?.role === "Admin" && (
        <div className="row">
          <div>
            <a href={"/user/create"} className="btn btn-success my-3 float-end">
              Create User
            </a>
          </div>
        </div>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.role}</td>
                  <td>
                    <a
                      href={"/user/" + user.id + "/edit"}
                      className="btn btn-primary m-1"
                    >
                      Edit
                    </a>
                    <button
                      type="button"
                      onClick={() => deleteUser(user.id)}
                      className="btn btn-danger m-1"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ViewUsers;
