import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Practice } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import { dateToSQLString } from "../../../util";
import * as formik from "formik";
import * as yup from "yup";
import { getPractice, updatePractice } from "../../../api/practices";

const EditPractice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [practice, setPractice] = useState<Practice>({
    id: id ? parseInt(id) : 0,
    name: "",
    abbreviation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    mapLink: "",
    notes: "",
    phone: "",
    email: "",
    scanner: "",
    mailbox: false,
    defaultDeliveryMethod: "",
    created: dateToSQLString(new Date()),
  });

  const schema = yup.object({
    name: yup.string().required("Required"),
    abbreviation: yup.string().required("Required"),
    address1: yup.string().required("Required"),
    address2: yup.string(),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    zip: yup
      .string()
      .required("Required")
      .matches(/\d{5}/, "Zip code must be five numbers"),
    mapLink: yup.string(),
    notes: yup.string(),
    phone: yup
      .string()
      .required("Required")
      .matches(
        /^\d{10}$/,
        "Phone number must be ten numbers with no extra characters"
      ),
    email: yup.string().required("Required"),
    scanner: yup.string().required("Required"),
    mailbox: yup.boolean(),
    defaultDeliveryMethod: yup.string(),
    created: yup
      .string()
      .required("Required")
      .matches(/^\d{4}-\d{2}-\d{2}$/),
  });
  const { Formik } = formik;

  useEffect(() => {
    if (!practice.name) {
      getPractice(id as unknown as number).then((response) => {
        Object.keys(response.data).forEach((key) => {
          if (response.data[key] == null) {
            response.data[key] = "";
          }
        });
        response.data.mailbox = Boolean(response.data.mailbox);
        response.data.created = dateToSQLString(
          new Date(response.data.created)
        );
        setPractice(response.data);
      });
    }
  });

  const handleOnSubmit = (values: formik.FormikValues) => {
    updatePractice(values)
      .then(() => {
        toast("Successfully updated practice. Redirecting to practice home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/practices");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error creating practice: ", error);
        toast("error creating practice: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">
          Update Practice: {practice.name}
        </h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={practice}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} className="row g-3">
              <Form.Group
                controlId="name"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  {touched.name && errors.name ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="abbreviation"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Abbreviation</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="abbreviation"
                    value={values.abbreviation}
                    onChange={handleChange}
                    isInvalid={!!errors.abbreviation}
                  />
                  {touched.abbreviation && errors.abbreviation ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.abbreviation}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="address1"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Address Line 1</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="address1"
                    value={values.address1}
                    onChange={handleChange}
                    isInvalid={!!errors.address1}
                  />
                  {touched.address1 && errors.address1 ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.address1}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="address2"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Address Line 2 &#40;Optional&#41;</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="address2"
                    value={values.address2 ?? ""}
                    onChange={handleChange}
                    isInvalid={!!errors.address2}
                  />
                  {touched.address2 && errors.address2 ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.address2}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="city"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>City</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                  />
                  {touched.city && errors.city ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="state"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>State</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    isInvalid={!!errors.state}
                  />
                  {touched.state && errors.state ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="zip" className="mt-3 col-md-6 offset-md-3">
                <Form.Label>Zip Code</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="zip"
                    value={values.zip}
                    onChange={handleChange}
                    isInvalid={!!errors.zip}
                  />
                  {touched.zip && errors.zip ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.zip}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="mailbox-checkbox"
                  label="Mailbox"
                  name="mailbox"
                  checked={values.mailbox}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                controlId="mapLink"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Google Maps Link &#40;Optional&#41;</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="mapLink"
                    value={values.mapLink}
                    onChange={handleChange}
                    isInvalid={!!errors.mapLink}
                  />
                  {touched.mapLink && errors.mapLink ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.mapLink}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="phone"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Phone</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                  />
                  {touched.phone && errors.phone ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="email"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Email</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  {touched.email && errors.email ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="scanner"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Scanner</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="scanner"
                    value={values.scanner}
                    onChange={handleChange}
                    isInvalid={!!errors.scanner}
                  />
                  {touched.scanner && errors.scanner ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.scanner}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="created"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Created</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="date"
                    name="created"
                    value={values.created}
                    onChange={handleChange}
                    isInvalid={!!errors.created}
                  />
                  {touched.created && errors.created ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.created}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Label>Default Delivery Method</Form.Label>
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-del"
                    type="radio"
                    name="defaultDeliveryMethod"
                    value="DEL"
                    checked={values.defaultDeliveryMethod === "DEL"}
                    onChange={handleChange}
                    isInvalid={!!errors.defaultDeliveryMethod}
                  />
                  <Form.Label htmlFor="radio-del" className="mx-3">
                    DEL
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-usps"
                    type="radio"
                    name="defaultDeliveryMethod"
                    value="USPS"
                    checked={values.defaultDeliveryMethod === "USPS"}
                    onChange={handleChange}
                    isInvalid={!!errors.defaultDeliveryMethod}
                  />
                  <Form.Label htmlFor="radio-usps" className="mx-3">
                    USPS
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-saver"
                    type="radio"
                    name="defaultDeliveryMethod"
                    value="UPS SAVER"
                    checked={values.defaultDeliveryMethod === "UPS SAVER"}
                    onChange={handleChange}
                    isInvalid={!!errors.defaultDeliveryMethod}
                  />
                  <Form.Label htmlFor="radio-saver" className="mx-3">
                    UPS SAVER
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-am"
                    type="radio"
                    name="defaultDeliveryMethod"
                    value="UPS AM"
                    checked={values.defaultDeliveryMethod === "UPS AM"}
                    onChange={handleChange}
                    isInvalid={!!errors.defaultDeliveryMethod}
                  />
                  <Form.Label htmlFor="radio-am" className="mx-3">
                    UPS AM
                  </Form.Label>
                </InputGroup>
                {touched.defaultDeliveryMethod &&
                errors.defaultDeliveryMethod ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.defaultDeliveryMethod}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="notes"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Notes &#40;Optional&#41;</Form.Label>
                <Form.Control
                  className="input-control"
                  as="textarea"
                  rows={3}
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Update Practice
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default EditPractice;
