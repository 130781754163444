import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { AxiosError } from "axios";
import { Dentist, Practice } from "../../../types/model";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import { DentistOption } from "../../../types/utility";
import { ToastContainer, toast } from "react-toastify";
import { dateToSQLString } from "../../../util";
import { assignDentistsToPractice, getPractice } from "../../../api/practices";
import { getDentists } from "../../../api/dentists";

const AddDentistToPractice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dentists, setDentists] = useState<DentistOption[]>([]);
  const [selectedDentists, setSelectedDentists] = useState<DentistOption[]>([]);
  const [practice, setPractice] = useState<Practice>({
    id: id ? parseInt(id) : 0,
    name: "",
    abbreviation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    mapLink: "",
    notes: "",
    phone: "",
    email: "",
    scanner: "",
    mailbox: false,
    defaultDeliveryMethod: "",
    created: dateToSQLString(new Date()),
  });

  useEffect(() => {
    if (!practice.name) {
      getPractice(id as unknown as number)
        .then((response) => {
          setPractice(response.data);
        })
        .catch((error: AxiosError) => {
          console.warn("error fetching practice: ", error);
          toast("error fetching practice: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
    if (!dentists.length) {
      getDentists().then((response) => {
        const dentistOptions = response.data.map((dentist: Dentist) => {
          return {
            id: dentist.id,
            name:
              (dentist.docFirst ? dentist.docFirst + " " : "") +
              dentist.docName,
          };
        });
        setDentists(dentistOptions);
      });
    }
  });

  const handleOnSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!selectedDentists.length) {
      toast("Please select at least one dentist to add to this practice", {
        type: "error",
        theme: "colored",
      });
    } else {
      assignDentistsToPractice(id as unknown as number, selectedDentists.map((dentist) => {
        return dentist.id;
      }))
        .then(() => {
          toast(
            "Successfully added dentist" +
              (selectedDentists.length > 1 ? "s" : "") +
              " to practice",
            {
              type: "success",
              theme: "colored",
              autoClose: 1500,
            }
          );
          setTimeout(() => {
            navigate("/practices");
          }, 1500);
        })
        .catch((error: AxiosError) => {
          console.warn("error adding dentists to practice: ", error);
          toast("error adding dentists to practice: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  };

  const handleInputChange = (selected: any) => {
    setSelectedDentists(selected);
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">
          Add Dentists to {practice.name}
        </h1>
        <Form onSubmit={handleOnSubmit} className="row g-3">
          <Form.Group
            className="mt-3 col-md-6 offset-md-3"
            controlId="selectedDentists"
          >
            <Form.Label>Select Dentists</Form.Label>
            <Typeahead
              id="select-dentists-dropdown"
              multiple
              onChange={handleInputChange}
              options={dentists}
              placeholder="Choose one or more dentists"
              selected={selectedDentists}
              labelKey="name"
            />
          </Form.Group>
          <Form.Group className="mt-3 col-md-6 offset-md-3">
            <Button
              variant="primary"
              type="submit"
              className="submit-btn float-end"
            >
              Add Dentist{selectedDentists.length > 1 ? "s" : ""}
            </Button>
          </Form.Group>
        </Form>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default AddDentistToPractice;
