import { PatientCase } from "../../../types/model"

export default function Dentist({ patientCase, dentists }: { patientCase: PatientCase, dentists: Record<string, { niceName: string; lastName: string; status: string }> }) {
  return (
    <td>
      <a
        href={"/dentist/" + patientCase.dentistId + "/view"}
        className="text-reset"
      >
        {dentists[patientCase.dentistId.toString()]?.niceName}
      </a>
    </td>
  )
}