import { PatientCase } from "../../../types/model"
import { dateToShortTimestamp } from "../../../util";

export default function Received({ patientCase }: { patientCase: PatientCase }) {
  const date = new Date(patientCase.receivedTime);

  return (
    <td className="column received">
      {dateToShortTimestamp(date)}
    </td>
  )
}