import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { DentistStatus } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import * as formik from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { createDentist, getDentistStatuses } from "../../../api/dentists";

const CreateDentist = () => {
  const [statuses, setStatuses] = useState<DentistStatus[]>([]);
  const navigate = useNavigate();
  const schema = yup.object({
    status: yup.string().required("Required"),
    docName: yup.string().required("Required"),
    docFirst: yup.string(),
    docEmail: yup.string(),
    notes: yup.string(),
    assistName: yup.string(),
    docPhone: yup
      .string()
      .matches(
        /^\d{10}$/,
        "Phone number must be ten numbers with no extra characters"
      ),
    assistPhone: yup
      .string()
      .matches(
        /^\d{10}$/,
        "Phone number must be ten numbers with no extra characters"
      ),
  });
  const { Formik } = formik;

  useEffect(() => {
    if (!statuses.length) {
      getDentistStatuses()
        .then((response) => {
          setStatuses(response.data);
        })
        .catch((error: AxiosError) => {
          console.warn("error fetching statuses: ", error);
          toast("error fetching statuses: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  });

  const handleOnSubmit = (values: formik.FormikValues) => {
    createDentist(values)
      .then(() => {
        toast("Successfully created dentist. Redirecting to dentist home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/dentists");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error creating dentist: ", error);
        toast("error creating dentist: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Create Dentist</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={{
            status: "PROS",
            docName: "",
            docFirst: "",
            assistName: "",
            docPhone: "",
            assistPhone: "",
            docEmail: "",
            notes: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form
              onSubmit={handleSubmit}
              className="row g-3"
              autoComplete="off"
            >
              <Form.Group
                controlId="docFirst"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Dr. First Name &#40;Optional&#41;</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="docFirst"
                    value={values.docFirst}
                    onChange={handleChange}
                    isInvalid={!!errors.docFirst}
                  />
                  {touched.docFirst && errors.docFirst ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.docFirst}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="docName"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Dr. Last Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="docName"
                    value={values.docName}
                    onChange={handleChange}
                    isInvalid={!!errors.docName}
                  />
                  {touched.docName && errors.docName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.docName}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="docPhone"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Dr. Cell</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="docPhone"
                    value={values.docPhone}
                    onChange={handleChange}
                    isInvalid={!!errors.docPhone}
                  />
                  {touched.docPhone && errors.docPhone ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.docPhone}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="docEmail"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Dr. Email</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="docEmail"
                    value={values.docEmail}
                    onChange={handleChange}
                    isInvalid={!!errors.docEmail}
                  />
                  {touched.docEmail && errors.docEmail ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.docEmail}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="status"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Status</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                >
                  {statuses &&
                    statuses.map((status) => {
                      return <option value={status.name}>{status.name}</option>;
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group
                controlId="assistName"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Assistant</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="assistName"
                    value={values.assistName}
                    onChange={handleChange}
                    isInvalid={!!errors.assistName}
                  />
                  {touched.assistName && errors.assistName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.assistName}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="assistPhone"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Assistant Phone</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="assistPhone"
                    value={values.assistPhone}
                    onChange={handleChange}
                    isInvalid={!!errors.assistPhone}
                  />
                  {touched.assistPhone && errors.assistPhone ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.assistPhone}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="notes"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Notes &#40;Optional&#41;</Form.Label>
                <Form.Control
                  className="input-control"
                  as="textarea"
                  rows={3}
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Create Dentist
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default CreateDentist;
