import { FormikValues } from "formik";
import apiClient from "./api-client";

export function getDentists() {
  return apiClient.get("/dentists");
}

export function getDentist(id: number) {
  return apiClient.get(`/dentist?id=${id}`);
}

export function createDentist(values: FormikValues) {
  return apiClient.post("/dentist", values);
}

export function updateDentist(values: FormikValues) {
  return apiClient.put("/dentist", values);
}

export function getDentistStatuses() {
  return apiClient.get("/dentist/statuses");
}

export function getDentistActiveStatuses() {
  return apiClient.get("/dentist/statuses/active");
}

export function getActiveDentists() {
  return apiClient.get("/dentists/active");
}

export function getDentistPractices() {
  return apiClient.get("/dentist/practice/relationships");
}

export function getDentistsForPractice(practiceId: number) {
  return apiClient.get(`/dentists/practice?id=${practiceId}`);
}

export function destroyDentist(id: number) {
  return apiClient.delete(`/dentist?id=${id}`);
}