import axios from "axios";
import { API_BASE_URL } from "../consts";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  response => response, 
  error => {
    if (error?.response?.status === 401) {
      window.location.href = '/logout';
    }
    return Promise.reject(error);
  }
)

export default apiClient;