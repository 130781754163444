import { Table } from "react-bootstrap";
import { Dentist, DentistPractice, Practice } from "../../../types/model";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import useToken from "../../UseToken";
import { ToastContainer, toast } from "react-toastify";
import { formatPhone } from "../../../util";
import { destroyPractice, getPractices } from "../../../api/practices";
import { getDentistPractices, getDentists } from "../../../api/dentists";

const ViewPractices = () => {
  const [practices, setPractices] = useState<Practice[]>([]);
  const [fetchedPractices, setFetchedPractices] = useState<boolean>(false);
  const [fetchedDentists, setFetchedDentists] = useState<boolean>(false);
  const [dentists, setDentists] = useState<{
    [key: string]: { niceName: string; lastName: string; status: string };
  }>({});
  const [fetchedDentistPractices, setFetchedDentistPractices] =
    useState<boolean>(false);
  const [practiceDentists, setPracticeDentists] = useState<{
    [key: string]: number[];
  }>({});
  const { token } = useToken();

  const getAllPractices = () => {
    getPractices()
      .then((response) => {
        setPractices(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    if (!fetchedPractices) {
      setFetchedPractices(true);
      getAllPractices();
    }
  }, [fetchedPractices]);

  useEffect(() => {
    if (!fetchedDentists) {
      setFetchedDentists(true);
      getAllDentists();
    }
  }, [fetchedDentists]);

  useEffect(() => {
    if (!fetchedDentistPractices) {
      setFetchedDentistPractices(true);
      getDentistPracticeMap();
    }
  }, [fetchedDentistPractices]);

  const getAllDentists = () => {
    getDentists()
      .then((response) => {
        const dentistMap: {
          [key: string]: { niceName: string; lastName: string; status: string };
        } = {};
        response.data.forEach((dentist: Dentist) => {
          dentistMap[dentist.id.toString()] = {
            niceName:
              (dentist.docFirst
                ? dentist.docFirst.substring(0, 1) + ". "
                : "") + dentist.docName,
            lastName: dentist.docName,
            status: dentist.status,
          };
        });
        setDentists(dentistMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all dentists: ", error);
        toast("error getting all dentists: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const getDentistPracticeMap = () => {
    getDentistPractices()
      .then(async (response) => {
        const practiceDentistMap: { [key: string]: number[] } = {};
        await response.data.forEach((rel: DentistPractice) => {
          let key = rel.practiceId.toString();
          if (practiceDentistMap[key]) {
            practiceDentistMap[key].push(rel.dentistId);
          } else {
            practiceDentistMap[key] = [rel.dentistId];
          }
        });
        setPracticeDentists(practiceDentistMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const deletePractice = (id: number) => {
    destroyPractice(id)
      .then(() => {
        toast("Successfully deleted practice", {
          type: "success",
          theme: "colored",
        });
        setPractices(
          practices.filter((practice) => {
            return practice.id !== id;
          })
        );
      });
  };

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1">
        {token && (token.role === "Admin" || token.role === "Case Manager") && (
          <div className="row">
            <div>
              <a
                href={"/practice/create"}
                className="btn btn-success my-3 float-end"
              >
                Create Practice
              </a>
            </div>
          </div>
        )}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Scanner</th>
              <th>Mailbox</th>
              <th>Ship</th>
              <th>Created</th>
              <th>{"Dentist(s)"}</th>
              {token &&
                (token.role === "Admin" || token.role === "Case Manager") && (
                  <th>Actions</th>
                )}
            </tr>
          </thead>
          <tbody>
            {practices &&
              practices.map((practice) => {
                return (
                  <tr key={practice.id}>
                    <td>
                      <a
                        href={"/practice/" + practice.id + "/view"}
                        className="text-reset"
                      >
                        {practice.name + " (" + practice.abbreviation + ")"}
                      </a>
                    </td>
                    <td>{formatPhone(practice.phone)}</td>
                    <td>{practice.email}</td>
                    <td>{practice.scanner}</td>
                    <td>{practice.mailbox ? "Yes" : "No"}</td>
                    <td>{practice.defaultDeliveryMethod}</td>
                    <td>{practice.created}</td>
                    <td>
                      {practiceDentists[practice.id.toString()] &&
                        practiceDentists[practice.id.toString()].map(
                          (dentId: number) => {
                            return (
                              <a
                                href={"/dentist/" + dentId + "/view"}
                                className="text-reset me-2"
                              >
                                {dentists[dentId]?.niceName}
                              </a>
                            );
                          }
                        )}
                    </td>
                    {token &&
                      (token.role === "Admin" ||
                        token.role === "Case Manager") && (
                        <td>
                          <a
                            href={"/practice/" + practice.id + "/edit"}
                            className="btn btn-primary m-1"
                          >
                            Edit
                          </a>
                          <a
                            href={"/practice/" + practice.id + "/dentists"}
                            className="btn btn-success m-1"
                          >
                            Add Dentists
                          </a>
                          {token && token.role === "Admin" && (
                            <button
                              type="button"
                              onClick={() => deletePractice(practice.id)}
                              className="btn btn-danger m-1"
                            >
                              Delete
                            </button>
                          )}
                        </td>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <ToastContainer></ToastContainer>
      </div>
    </div>
  );
};

export default ViewPractices;
