import { PatientCase } from "./types/model";

export const dateToSQLString = (date: Date): string => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

export const formatPhone = (phone: string): string => {
  if (!phone) {
    return "";
  }
  
  return (
    "(" + phone.slice(0, 3) + ") " + phone.slice(3, 6) + "-" + phone.slice(6)
  );
};

export const dateToShortTimestamp = (date: Date): string => {
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
  );
};

export const convertToUserTimezone = (date: Date): Date => {
  // Convert the date to the user's timezone
  const dateString = date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
  const laDate = new Date(dateString);

  // Get the time difference between LA and the user's timezone in minutes
  const timeOffset = laDate.getTimezoneOffset() - date.getTimezoneOffset();

  // Create a new Date object with the adjusted time
  const userDate = new Date(laDate.getTime() + timeOffset * 60000);

  return userDate;
};


export const dateToMonthAndDay = (dateString: string): string => {
  if (!dateString) {
    return "";
  }
  dateString = dateString.replace(/-/g, "/");
  const date = new Date(dateString);
  return date.getMonth() + 1 + "/" + date.getDate();
};

export const generateLabel = (
  patientCase: PatientCase,
  deliveryPracticeAbbreviation: string,
  dentistName: string,
  dentistStatus: string,
  shade: string
): string => {
  return (
    deliveryPracticeAbbreviation +
    " " +
    dentistName +
    " " +
    dentistStatus +
    "\n" +
    patientCase.patientName +
    "\n" +
    patientCase.units +
    " " +
    shade +
    " " +
    (patientCase.rush ? "RUSH " : "") +
    (patientCase.bioCopyFlag ? "BIO COPY " : "") +
    (patientCase.remakeFlag ? "RMK" : "") +
    (patientCase.opposingAdjustFlag ? "\nADJ OPP " : "") +
    (patientCase.proximalAdjustFlag
      ? patientCase.opposingAdjustFlag
        ? "PRX"
        : "\nADJ PRX"
      : "")
  );
};

export const determineReturnBy = (): string => {
  const date = new Date();
  if (date.getHours() > 14) {
    // if 3pm or later, consider as tomorrow
    date.setDate(date.getDate() + 1);
  }
  date.setDate(date.getDate() + 1); // received day + 1
  if (date.getDay() === 0) {
    // push Sunday to Monday
    date.setDate(date.getDate() + 1);
  } else if (date.getDay() === 6) {
    // push Saturday to Monday
    date.setDate(date.getDate() + 2);
  }
  return dateToSQLString(date);
};

export const getTrackingLink = (
  deliveryMethod: string,
  trackingNumber: string
): string => {
  if (deliveryMethod === "DEL") {
    return "";
  } else if (deliveryMethod === "USPS") {
    return (
      "https://tools.usps.com/go/TrackConfirmAction?tLabels=" + trackingNumber
    );
  } else if (deliveryMethod === "UPS AM" || deliveryMethod === "UPS SAVER") {
    return "https://www.ups.com/track?tracknum=" + trackingNumber;
  } else {
    console.warn("Delivery method [", deliveryMethod, "] not recognized");
    return "";
  }
};

export const getClassesForStatus = (status: string): string => {
  let className = "";
  switch (status) {
    case "received":
      className = "bg-light";
      break;
    case "Designed":
      className = "bg-info";
      break;
    case "Late Milled":
      className = "bg-late-milled text-white";
      break;
    case "Nested":
      className = "bg-secondary text-white";
      break;
    case "Milled":
      className = "bg-warning";
      break;
    case "Finished":
      className = "bg-finished";
      break;
    case "Shipped":
      className = "bg-primary text-white";
      break;
    case "Billed":
      className = "bg-success text-white";
      break;
    case "Hold":
      className = "bg-danger text-white";
      break;
    case "Booked":
      className = "bg-booked text-white";
      break;
  }
  return className;
};

export const abbreviateFirstName = (name: string): string => {
  let words = name.split(' ')
  let returnVal = words[0].charAt(0) + '. '
  for (let i = 1; i < words.length; i++) {
    returnVal += words[i] + ' '
  }
  return returnVal.slice(0, returnVal.length - 1)
}
