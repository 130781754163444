import { Table } from "react-bootstrap";
import { Dentist, DentistPractice, Practice } from "../../../types/model";
import { useEffect, useState } from "react";
import useToken from "../../UseToken";
import { ToastContainer, toast } from "react-toastify";
import { formatPhone } from "../../../util";
import { getDentists, getDentistPractices, destroyDentist } from "../../../api/dentists";
import { getPractices } from "../../../api/practices";
import { AxiosError } from "axios";
import ColumnHeader from "../../table/ColumnHeader";

const ViewDentists = () => {
  const [dentists, setDentists] = useState<Dentist[]>([]);
  const [fetchedDentists, setFetchedDentists] = useState<boolean>(false);
  const [practices, setPractices] = useState<{
    [key: string]: { abr: string; name: string };
  }>({});
  const [fetchedPractices, setFetchedPractices] = useState<boolean>(false);
  const [fetchedDentistPractices, setFetchedDentistPractices] =
    useState<boolean>(false);
  const [dentistPractices, setDentistPractices] = useState<{
    [key: string]: number[];
  }>({});
  const [sortName, setSortName] = useState<string>("name");
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>("desc");  
  const { token } = useToken();

  const getAllDentists = () => {
    getDentists()
      .then((response) => {
        setDentists(response.data);
      })
      .catch((error: AxiosError) => {
        toast("error getting all dentists: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    if (!fetchedPractices) {
      setFetchedPractices(true);
      getAllPractices();
    }
  }, [fetchedPractices]);

  useEffect(() => {
    if (!fetchedDentists) {
      setFetchedDentists(true);
      getAllDentists();
    }
  }, [fetchedDentists]);

  useEffect(() => {
    if (!fetchedDentistPractices) {
      setFetchedDentistPractices(true);
      allDentistPractices();
    }
  }, [fetchedDentistPractices]);

  const getAllPractices = () => {
    getPractices()
      .then((response) => {
        const practiceMap: { [key: string]: { abr: string; name: string } } =
          {};
        response.data.forEach((practice: Practice) => {
          practiceMap[practice.id.toString()] = {
            abr: practice.abbreviation,
            name: practice.name,
          };
        });
        setPractices(practiceMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const allDentistPractices = () => {
    getDentistPractices()
      .then(async (response) => {
        const practiceDentistMap: { [key: string]: number[] } = {};
        await response.data.forEach((rel: DentistPractice) => {
          let key = rel.dentistId.toString();
          if (practiceDentistMap[key]) {
            practiceDentistMap[key].push(rel.practiceId);
          } else {
            practiceDentistMap[key] = [rel.practiceId];
          }
        });
        setDentistPractices(practiceDentistMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const deleteDentist = (id: number) => {
    destroyDentist(id)
      .then(() => {
        toast("Successfully deleted dentist", {
          type: "success",
          theme: "colored",
        });
        setDentists(
          dentists.filter((dentist) => {
            return dentist.id !== id;
          })
        );
      });
  };

  const handleSortClick = (name: string) => {
    if (name === sortName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortName(name);
      setSortDirection("asc");
    }
  };

  const handleSort = (a: Dentist, b: Dentist) =>  {
    if (sortName === "name") {
      return sortDirection === "asc"
        ? a.docName.localeCompare(b.docName)
        : b.docName.localeCompare(a.docName);
    } else if (sortName === "status") {
      return sortDirection === "asc"
        ? a.status.localeCompare(b.status)
        : b.status.localeCompare(a.status);
    }
  };

  return (
    <div className="container">
      {token && (token.role === "Admin" || token.role === "Case Manager") && (
        <div className="row">
          <div>
            <a
              href={"/dentist/create"}
              className="btn btn-success my-3 float-end"
            >
              Create Dentist
            </a>
          </div>
        </div>
      )}
      <Table striped bordered hover className="dentists-table">
        <thead>
          <tr>
            <ColumnHeader
              sortable={true} 
              onSort={handleSortClick} 
              sortName={sortName}
              sortDirection={sortDirection} 
              name="name"
            >Dentist</ColumnHeader>
            <th>Dentist Cell</th>
            <th>Dentist Email</th>
            <ColumnHeader
              sortable={true} 
              onSort={handleSortClick} 
              sortName={sortName}
              sortDirection={sortDirection} 
              name="status"
            >Status</ColumnHeader>
            <th>Assistant</th>
            <th>Assistant Phone</th>
            <th>{"Practice(s)"}</th>
            {token &&
              (token.role === "Admin" || token.role === "Case Manager") && (
                <th>Actions</th>
              )}
          </tr>
        </thead>
        <tbody>
          {dentists &&
            dentists?.sort((a, b) => handleSort(a,b) || 0).map((dentist) => {
              return (
                <tr key={dentist.id}>
                  <td>
                    <a
                      href={"/dentist/" + dentist.id + "/view"}
                      className="text-reset"
                    >
                      {(dentist.docFirst ? dentist.docFirst + " " : "") +
                        dentist.docName}
                    </a>
                  </td>
                  <td>
                    {dentist.docPhone ? formatPhone(dentist.docPhone) : ""}
                  </td>
                  <td>{dentist.docEmail}</td>
                  <td>{dentist.status}</td>
                  <td>{dentist.assistName}</td>
                  <td>
                    {dentist.assistPhone
                      ? formatPhone(dentist.assistPhone)
                      : ""}
                  </td>
                  <td>
                    {dentistPractices[dentist.id.toString()] &&
                      dentistPractices[dentist.id.toString()].map(
                        (practId: number) => {
                          return (
                            <a
                              title={practices[practId]?.name}
                              href={"/practice/" + practId + "/view"}
                              className="text-reset me-2"
                            >
                              {practices[practId]?.abr}
                            </a>
                          );
                        }
                      )}
                  </td>
                  {token &&
                    (token.role === "Admin" ||
                      token.role === "Case Manager") && (
                      <td>
                        <a
                          href={"/dentist/" + dentist.id + "/edit"}
                          className="btn btn-primary m-1"
                        >
                          Edit
                        </a>
                        <a
                          href={"/dentist/" + dentist.id + "/practice"}
                          className="btn btn-success m-1"
                        >
                          Add to Practice
                        </a>
                        {token && token.role === "Admin" && (
                          <button
                            type="button"
                            onClick={() => deleteDentist(dentist.id)}
                            className="btn btn-danger m-1"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    )}
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ViewDentists;
