import { PatientCase } from "../../../types/model"
import classNames from "classnames";

export default function Engraving({ patientCase, allCases }: { patientCase: PatientCase, allCases: PatientCase[] }) {

  const isDuplicateEng = allCases.filter((pc) => pc.engraving === patientCase.engraving).length > 1;

  return (
    <td 
      className={classNames('column engraving', {
        'text-bg-danger': isDuplicateEng
      })}
      {...isDuplicateEng ? { 'data-tooltip-id': 'tooltip' } : {}}
      {...isDuplicateEng ? { 'data-tooltip-content': 'Duplicate Engraving' } : {}}
    >{patientCase.engraving}</td>
  )
}