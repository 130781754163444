import { FormikValues } from "formik";
import apiClient from "./api-client";

export function getCase(id: string) {
  return apiClient.get(`/case?id=${id}`);
}

export function getActiveCases() {
  return apiClient.get("/cases/active");
}

export function getCasesBetweenDates(startDate: string, endDate: string) {
  return apiClient.get("/cases/between?startDate=" +
  startDate +
  (endDate ? "&endDate=" + endDate : ""));
}

export function createCase(values: FormikValues) {
  return apiClient.post("/case", values);
}

export function updateCase(values: FormikValues) {
  return apiClient.put("/case", values);
}

export function destroyCase(id: string) {
  return apiClient.delete(`/case?id=${id}`);
}

export function updateCaseStatus(id: number, status: string) {
  return apiClient.patch(`/case/completionStatus`, { id, status });
}

export function updateCaseTrackingNumber(id: number, trackingNumber: string) {
  return apiClient.patch(`/case/trackingNumber`, { id, trackingNumber });
}

export function updateCaseNotes(id: number, notes: string) {
  return apiClient.patch(`/case/notes`, { id, notes });
}

export function updateCaseAdjustOpposing(id: number, adjOp: boolean) {
  return apiClient.patch(`/case/adjustOpposing`, { id, adjOp });
}

export function updateCaseAdjustProximal (id: number, adjProx: boolean) {
  return apiClient.patch(`/case/adjustProximal`, { id, adjProx });
}

export function updateCaseAdjustBite (id: number, bite: boolean) {
  return apiClient.patch(`/case/adjustBite`, { id, bite });
}

export function updateCasesMilledToShipped() {
  return apiClient.patch(`/cases/milled-shipped`);
}

export function updateCasesShippedToBilled() {
  return apiClient.patch(`/cases/shipped`);
}

export function getCasesForPractice(practiceId?: number, startDate?: string, endDate?: string) {
  return apiClient.get("/cases/practice/between?id=" +
  (practiceId ?? 0) +
  "&startDate=" +
  startDate +
  (endDate ? "&endDate=" + endDate : ""));
}