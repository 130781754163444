import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import UserIcon from "../icons/UserIcon";
import Dropdown, { DropdownItem } from "../nav/Dropdown";

export default function UserMenu({ name }: { name: string }) {
  return (
    <Dropdown
      trigger={
        <div className="tw-flex tw-items-center tw-gap-2">
          <UserIcon />
          <div className="tw-text-slate-100 tw-text-sm">{name}</div>
        </div>
      }
    >
      <DropdownItem icon={<ArrowLeftIcon />}>
        <a href="/logout" className="tw-no-underline tw-text-slate-800">Log Out</a>
      </DropdownItem>
    </Dropdown>
  )
}