import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Dentist, PatientCase, Practice } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import { dateToSQLString, getClassesForStatus, getTrackingLink } from "../../../util";
import useToken from "../../UseToken";
import { getCase } from "../../../api/cases";
import { getDentist } from "../../../api/dentists";
import { getPractice } from "../../../api/practices";

const ViewCase = () => {
  const { id } = useParams();
  const { token } = useToken();
  const [patientCase, setPatientCase] = useState<PatientCase>({
    id: id ? parseInt(id) : 0,
    receivedTime: new Date(),
    dentistId: 0,
    billingPracticeId: 0,
    deliveryPracticeId: 0,
    patientName: "",
    units: "",
    adapter: "",
    shade: "",
    returnBy: "",
    completionStatus: "CREATED",
    puckPosition: "",
    deliveryMethod: "",
    trackingNumber: "",
    engraving: "",
    notes: "",
    remakeFlag: false,
    bioCopyFlag: false,
    proximalAdjustFlag: false,
    opposingAdjustFlag: false,
    millOnlyFlag: false,
    rush: false,
    cost: '0',
    anterior: 0,
    posterior: 0,
    bite: false
  });

  const [dentist, setDentist] = useState<Dentist>({
    id: 0,
    status: "",
    docName: "",
    docFirst: "",
    assistName: "",
    docPhone: "",
    assistPhone: "",
    docEmail: "",
    notes: ""
  });

  const [billingPractice, setBillingPractice] = useState<Practice>({
    id: 0,
    name: "",
    abbreviation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    mapLink: "",
    notes: "",
    phone: "",
    email: "",
    scanner: "",
    mailbox: false,
    defaultDeliveryMethod: "",
    created: dateToSQLString(new Date()),
  });

  const [deliveryPractice, setDeliveryPractice] = useState<Practice>({
    id: 0,
    name: "",
    abbreviation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    mapLink: "",
    notes: "",
    phone: "",
    email: "",
    scanner: "",
    mailbox: false,
    defaultDeliveryMethod: "",
    created: dateToSQLString(new Date()),
  });

  useEffect(() => {
    if (!patientCase.patientName && id) {
      getCase(id).then((response) => {
        Object.keys(response.data).forEach((key) => {
          if (response.data[key] == null) {
            response.data[key] = "";
          }
        });
        setPatientCase(response.data);
      });
    }
  });

  useEffect(() => {
    if (patientCase.dentistId > 0) {
      getDentist(patientCase.dentistId).then((response) => {
          Object.keys(response.data).forEach((key) => {
            if (response.data[key] == null) {
              response.data[key] = "";
            }
          });
          setDentist(response.data);
        });
    }
  }, [patientCase]);

  useEffect(() => {
    if (patientCase.billingPracticeId > 0) {
      getPractice(patientCase.billingPracticeId)
        .then((response) => {
          Object.keys(response.data).forEach((key) => {
            if (response.data[key] == null) {
              response.data[key] = "";
            }
          });
          setBillingPractice(response.data);
        });
    }
  }, [patientCase]);

  useEffect(() => {
    if (patientCase.deliveryPracticeId > 0) {
      if (patientCase.deliveryPracticeId === patientCase.billingPracticeId) {
        setDeliveryPractice(billingPractice);
      } else {
        getPractice(patientCase.deliveryPracticeId)
          .then((response) => {
            Object.keys(response.data).forEach((key) => {
              if (response.data[key] == null) {
                response.data[key] = "";
              }
            });
            setDeliveryPractice(response.data);
          });
      }
    }
  }, [patientCase, billingPractice]);

  const generateLabel = (): string => {
    return (
      deliveryPractice.abbreviation +
      " " +
      dentist.docName +
      " " +
      dentist.status +
      "\n" +
      patientCase.patientName +
      "\n" +
      patientCase.units +
      " " +
      patientCase.shade +
      " " +
      (patientCase.opposingAdjustFlag ? "ADJ OPP " : "") +
      (patientCase.proximalAdjustFlag
        ? patientCase.opposingAdjustFlag
          ? "PRX "
          : "ADJ PRX "
        : "") +
      (patientCase.bioCopyFlag ? "BIO COPY " : "") +
      (patientCase.remakeFlag ? "RMK" : "")
    );
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Viewing Case</h1>
        <div className="row my-3">
          <div className="col-md-6 offset-md-3">
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>Received Time</th>
                  <td>{new Date(patientCase.receivedTime).toLocaleString()}</td>
                </tr>
                <tr>
                  <th>Dentist</th>
                  <td>
                    {" "}
                    <a
                      href={"/dentist/" + patientCase.dentistId + "/view"}
                      className="text-reset"
                    >
                      {(dentist.docFirst ? dentist.docFirst + " " : "") +
                        dentist.docName}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Billing Practice</th>
                  <td>
                    <a
                      href={
                        "/practice/" + patientCase.deliveryPracticeId + "/view"
                      }
                      className="text-reset"
                    >
                      {billingPractice.name}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Delivery Practice</th>
                  <td>
                    <a
                      href={
                        "/practice/" + patientCase.deliveryPracticeId + "/view"
                      }
                      className="text-reset"
                    >
                      {deliveryPractice.name}
                    </a>
                  </td>
                </tr>
                <tr>
                  <th>Patient Name</th>
                  <td>{patientCase.patientName}</td>
                </tr>
                <tr>
                  <th>Teeth Numbers</th>
                  <td>{patientCase.units}</td>
                </tr>
                <tr>
                  <th>Adapter</th>
                  <td>{patientCase.adapter}</td>
                </tr>
                <tr>
                  <th>Shade</th>
                  <td>{patientCase.shade}</td>
                </tr>
                <tr>
                  <th>Anterior Units</th>
                  <td>{patientCase.anterior}</td>
                </tr>
                <tr>
                  <th>Posterior Units</th>
                  <td>{patientCase.posterior}</td>
                </tr>
                {token &&
                  (token.role === "Admin" || token.role === "Case Manager") && (
                    <tr>
                      <th>Cost</th>
                      <td>{patientCase.cost}</td>
                    </tr>
                  )}
                <tr>
                  <th>Return By</th>
                  <td>{patientCase.returnBy}</td>
                </tr>
                <tr>
                  <th>Completion Status</th>
                  <td
                    className={getClassesForStatus(
                      patientCase.completionStatus
                    )}
                  >
                    {patientCase.completionStatus}
                  </td>
                </tr>
                <tr>
                  <th>Puck Position</th>
                  <td>{patientCase.puckPosition}</td>
                </tr>
                <tr>
                  <th>Delivery Method</th>
                  <td>{patientCase.deliveryMethod}</td>
                </tr>
                <tr>
                  <th>Tracking Number</th>
                  <td>
                    {patientCase.trackingNumber && (
                      <a
                        href={getTrackingLink(
                          patientCase.deliveryMethod,
                          patientCase.trackingNumber
                        )}
                        target="_blank"
                        rel="noreferrer"
                        className="text-reset"
                      >
                        {patientCase.trackingNumber}
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Engraving</th>
                  <td>{patientCase.engraving}</td>
                </tr>
                <tr>
                  <th>Notes</th>
                  <td>{patientCase.notes}</td>
                </tr>
                <tr>
                  <th>Remake</th>
                  <td>{patientCase.remakeFlag ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Bio Copy</th>
                  <td>{patientCase.bioCopyFlag ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Adjust Proximal</th>
                  <td>{patientCase.proximalAdjustFlag ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Adjust Opposing</th>
                  <td>{patientCase.opposingAdjustFlag ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Adjust Bite</th>
                  <td>{patientCase.bite ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Mill Only</th>
                  <td>{patientCase.millOnlyFlag ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Rush</th>
                  <td>{patientCase.rush ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="row g-3">
          <div className="mt-3 col-md-6 offset-md-3">
            <Button
              type="button"
              className="btn btn-primary m-1"
              onClick={() => {
                navigator.clipboard.writeText(generateLabel());
                toast("Label copied to clipboard", {
                  type: "success",
                  theme: "colored",
                  autoClose: 3000,
                });
              }}
            >
              Copy Label
            </Button>
            {token &&
              (token.role === "Admin" || token.role === "Case Manager") && (
                <a
                  href={"/case/" + id + "/edit"}
                  className="btn btn-primary m-1 float-end"
                >
                  Edit Case
                </a>
              )}
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default ViewCase;
