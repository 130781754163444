import { Table } from "react-bootstrap";
import { MillPrice, Price } from "../../../types/model";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import useToken from "../../UseToken";
import { ToastContainer, toast } from "react-toastify";
import { destroyPrice, getMillPrices, getPrices } from "../../../api/prices";

const ViewPrices = () => {
  const [prices, setPrices] = useState<Price[]>([]);
  const [millPrices, setMillPrices] = useState<MillPrice[]>([]);
  const { token } = useToken();

  const getAllPrices = () => {
    getPrices()
      .then((response) => {
        setPrices(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all prices: ", error);
        toast("error getting all prices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const getAllMillPrices = () => {
    getMillPrices()
      .then((response) => {
        setMillPrices(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all mill prices: ", error);
        toast("error getting all mill prices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getAllPrices();
  }, []);

  useEffect(() => {
    getAllMillPrices();
  }, []);

  const deletePrice = (id: number) => {
    destroyPrice(id)
      .then(() => {
        toast("Successfully deleted price", {
          type: "success",
          theme: "colored",
        });
        setPrices(
          prices.filter((price) => {
            return price.id !== id;
          })
        );
      });
  };

  return (
    <div className="container">
      {token?.role === "Admin" && (
        <div className="row">
          <div>
            <a
              href={"/price/create"}
              className="btn btn-success my-3 float-end"
            >
              Create Price
            </a>
          </div>
        </div>
      )}
      <h3>Designed Unit Prices</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Dentist Status</th>
            <th>Anterior</th>
            <th>Multilayer</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {prices &&
            prices.map((price) => {
              return (
                <tr key={price.id}>
                  <td>{price.status}</td>
                  <td>{price.anteriorFlag ? "Yes" : "No"}</td>
                  <td>{price.multilayerFlag ? "Yes" : "No"}</td>
                  <td>{price.price}</td>
                  <td>
                    <a
                      href={"/price/" + price.id + "/edit"}
                      className="btn btn-primary m-1"
                    >
                      Edit
                    </a>
                    <button
                      type="button"
                      onClick={() => deletePrice(price.id)}
                      className="btn btn-danger m-1"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <h3>Milled Unit Prices</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Multilayer</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {millPrices &&
            millPrices.map((price) => {
              return (
                <tr key={price.id}>
                  <td>{price.multilayerFlag ? "Yes" : "No"}</td>
                  <td>{price.price}</td>
                  <td>
                    <a
                      href={"/price/mill/" + price.id + "/edit"}
                      className="btn btn-primary m-1"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ViewPrices;
