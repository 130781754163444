import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Price, DentistStatus } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import * as formik from "formik";
import * as yup from "yup";
import { getPrice, updatePrice } from "../../../api/prices";
import { getDentistStatuses } from "../../../api/dentists";

const EditPrice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialPrice, setInitialPrice] = useState<Price>({
    id: id ? parseInt(id) : 0,
    status: "",
    multilayerFlag: false,
    anteriorFlag: false,
    price: 0,
  });
  const [statuses, setStatuses] = useState<DentistStatus[]>([]);

  const schema = yup.object({
    status: yup.string().required("Required"),
    multilayerFlag: yup.boolean(),
    anteriorFlag: yup.boolean(),
    price: yup
      .number()
      .integer("Price must be a whole number")
      .required("Required"),
  });
  const { Formik } = formik;

  useEffect(() => {
    if (!initialPrice.status) {
      getPrice(id as unknown as number).then((response) => {
        response.data.anteriorFlag = Boolean(response.data.anteriorFlag);
        response.data.multilayerFlag = Boolean(response.data.multilayerFlag);
        setInitialPrice(response.data);
      });
    }
    if (!statuses.length) {
      getDentistStatuses()
        .then((response) => {
          setStatuses(response.data);
        })
        .catch((error: AxiosError) => {
          console.warn("error fetching statuses: ", error);
          toast("error fetching statuses: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  });

  const handleOnSubmit = (values: formik.FormikValues) => {
    updatePrice(values)
      .then(() => {
        toast("Successfully updated price. Redirecting to price home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/prices");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error updating price: ", error);
        toast("error updating price: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Update Price</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={initialPrice}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} className="row g-3">
              <Form.Group
                controlId="status"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Status</Form.Label>
                <InputGroup hasValidation>
                  <Form.Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    isInvalid={!!errors.status}
                  >
                    {statuses &&
                      statuses.map((status) => {
                        return (
                          <option value={status.name}>{status.name}</option>
                        );
                      })}
                  </Form.Select>
                  {touched.status && errors.status ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    type="checkbox"
                    id="anterior-checkbox"
                    label="Anterior"
                    name="anteriorFlag"
                    checked={values.anteriorFlag}
                    onChange={handleChange}
                  />
                  {touched.anteriorFlag && errors.anteriorFlag ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.anteriorFlag}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    type="checkbox"
                    id="multilayer-checkbox"
                    label="Multilayer"
                    name="multilayerFlag"
                    checked={values.multilayerFlag}
                    onChange={handleChange}
                  />
                  {touched.multilayerFlag && errors.multilayerFlag ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.multilayerFlag}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="price"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Price</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="number"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    isInvalid={!!errors.price}
                  />
                  {touched.price && errors.price ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.price}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Update Price
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default EditPrice;
