import { useState } from 'react';
import { UserToken } from '../types/utility';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = tokenString ? JSON.parse(tokenString) : null;
    return userToken
  };

  const [token, setToken] = useState<UserToken | null>(getToken());

  const saveToken = (userToken: {id: string, token: string, role: string} | null) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
