import { useEffect, useRef, useState } from "react";
import ChevronDownIcon from "../icons/ChevronDownIcon";

export function DropdownItem({ icon, children }: { icon?: React.ReactNode, children: React.ReactNode }) {
  return (
    <div className="tw-p-2 tw-text-sm tw-text-slate-600 hover:tw-bg-slate-100 hover:tw-text-slate-800 tw-cursor-pointer tw-flex tw-items-center tw-gap-1">
      <div className="tw-transform tw-scale-75">
        {icon}
      </div>
      {children}
    </div>
  )
}

export default function Dropdown({ trigger, children }: { trigger: React.ReactNode, children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="tw-relative" ref={ref}>
      <div onClick={() => setIsOpen(!isOpen)} className="tw-cursor-pointer tw-flex tw-items-center tw-gap-1">
        {trigger}
        <ChevronDownIcon />
      </div>
      {isOpen && (
        <div className="tw-absolute tw-top-8 tw-right-0 tw-bg-white tw-shadow-md tw-border tw-border-slate-200 tw-rounded-md tw-p-2 tw-z-10">
          {children}
        </div>
      )}
    </div>
  )
}