import { Button, Form, InputGroup } from "react-bootstrap";
import { getTrackingLink } from "../../../util";
import { PatientCase } from "../../../types/model";

export default function Tracking({ patientCase, newTrackingNums, setNewTrackingNums, updateTrackingNumber }: { patientCase: PatientCase, newTrackingNums: Record<number, string>, setNewTrackingNums: (newTrackingNums: Record<number, string>) => void, updateTrackingNumber: (id: number) => void}) {
  return (
    <td className="column tracking">
      {patientCase.trackingNumber && (
        <a
          href={getTrackingLink(
            patientCase.deliveryMethod,
            patientCase.trackingNumber
          )}
          target="_blank"
          rel="noreferrer"
          className="text-reset"
        >
          {patientCase.trackingNumber}
        </a>
      )}
      {!patientCase.trackingNumber &&
        patientCase.deliveryMethod !== "DEL" && (
          <InputGroup className="d-inline-flex m-1">
            <Form.Control
              className="input-control"
              type="text"
              value={newTrackingNums[patientCase.id]}
              onChange={(e) => {
                const trackingNums =
                  structuredClone(newTrackingNums);
                trackingNums[patientCase.id] = e.target.value;
                setNewTrackingNums(trackingNums);
              }}
            ></Form.Control>
            <Button
              variant="primary"
              type="button"
              className="submit-btn"
              onClick={() => {
                updateTrackingNumber(patientCase.id);
              }}
            >
              Save
            </Button>
          </InputGroup>
        )}
    </td>
  )
}