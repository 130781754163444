import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserToken } from "../../types/utility";
import { logout } from "../../api/auth";

const Logout = (props: { setToken: (userToken: UserToken | null) => void }) => {
  const navigate = useNavigate();
  useEffect(() => {
    logout()
      .then(() => {
        props.setToken(null);
        navigate("/cases");
      })
      .catch((error) => {
        console.warn("error logging out: ", error);
      });
  });

  return <div>You have been logged out. You will be redirected shortly</div>;
};

export default Logout;
