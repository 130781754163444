import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./styles.css";
import CreateUser from "./components/pages/user/CreateUser";
import ViewUsers from "./components/pages/user/UserHome";
import NavigationBar from "./components/Navigation";
import Login from "./components/pages/Login";
import useToken from "./components/UseToken";
import Logout from "./components/pages/Logout";
import ViewDentists from "./components/pages/dentist/DentistHome";
import EditUser from "./components/pages/user/EditUser";
import EditDentist from "./components/pages/dentist/EditDentist";
import CreateDentist from "./components/pages/dentist/CreateDentist";
import ViewPractices from "./components/pages/practice/PracticeHome";
import CreatePractice from "./components/pages/practice/CreatePractice";
import EditPractice from "./components/pages/practice/EditPractice";
import AddDentistToPractice from "./components/pages/practice/AddDentistToPractice";
import AddPracticeToDentist from "./components/pages/dentist/AddPracticeToDentist";
import ViewPrices from "./components/pages/price/PriceHome";
import CreatePrice from "./components/pages/price/CreatePrice";
import EditPrice from "./components/pages/price/EditPrice";
import ViewCases from "./components/pages/case/CaseHome";
import CreateCase from "./components/pages/case/CreateCase";
import EditCase from "./components/pages/case/EditCase";
import ViewDentist from "./components/pages/dentist/ViewDentist";
import ViewPractice from "./components/pages/practice/ViewPractice";
import ViewCase from "./components/pages/case/ViewCase";
import EditMillPrice from "./components/pages/price/EditMillPrice";
import FilterCases from "./components/pages/case/FilterCases";
import Reports from "./components/pages/reports";

function App() {
  const { token, setToken } = useToken();

  return (
    <>
      {!token && <Login setToken={setToken} />}
      {token && (
        <Router>
          <NavigationBar></NavigationBar>
          <Routes>
            {token &&
              (token.role === "Admin" ||
                token.role === "Case Manager" ||
                token.role === "Basic") && (
                <Route
                  path="/*"
                  element={<Navigate to="/cases"></Navigate>}
                ></Route>
              )}
            {token && token.role === "Practice" && (
              <Route
                path="/*"
                element={<Navigate to="/cases/filter"></Navigate>}
              ></Route>
            )}
            {token &&
              (token.role === "Admin" ||
                token.role === "Case Manager" ||
                token.role === "Basic") && (
                <Route path="/cases" element={<ViewCases></ViewCases>}></Route>
              )}
            <Route
              path="/cases/filter"
              element={<FilterCases></FilterCases>}
            ></Route>
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/case/:id/edit"
                element={<EditCase></EditCase>}
              ></Route>
            )}
            {(token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <Route
                path="/case/:id/view"
                element={<ViewCase></ViewCase>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/case/create"
                element={<CreateCase></CreateCase>}
              ></Route>
            )}
            {(token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <Route
                path="/dentists"
                element={<ViewDentists></ViewDentists>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/dentist/:id/edit"
                element={<EditDentist></EditDentist>}
              ></Route>
            )}
            {(token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <Route
                path="/dentist/:id/view"
                element={<ViewDentist></ViewDentist>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/dentist/create"
                element={<CreateDentist></CreateDentist>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/dentist/:id/practice"
                element={<AddPracticeToDentist></AddPracticeToDentist>}
              ></Route>
            )}
            {(token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <Route
                path="/practices"
                element={<ViewPractices></ViewPractices>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/practice/:id/edit"
                element={<EditPractice></EditPractice>}
              ></Route>
            )}
            {(token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <Route
                path="/practice/:id/view"
                element={<ViewPractice></ViewPractice>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/practice/create"
                element={<CreatePractice></CreatePractice>}
              ></Route>
            )}
            {(token.role === "Admin" || token.role === "Case Manager") && (
              <Route
                path="/practice/:id/dentists"
                element={<AddDentistToPractice></AddDentistToPractice>}
              ></Route>
            )}
            {token.role === "Admin" && (
              <Route path="/prices" element={<ViewPrices></ViewPrices>}></Route>
            )}
            {token.role === "Admin" && (
              <Route
                path="/price/:id/edit"
                element={<EditPrice></EditPrice>}
              ></Route>
            )}
            {token.role === "Admin" && (
              <Route
                path="/price/mill/:id/edit"
                element={<EditMillPrice></EditMillPrice>}
              ></Route>
            )}
            {token.role === "Admin" && (
              <Route
                path="/price/create"
                element={<CreatePrice></CreatePrice>}
              ></Route>
            )}
            {token.role === "Admin" && (
              <Route path="/users" element={<ViewUsers></ViewUsers>}></Route>
            )}
            {(token.role === "Admin" || token.role === 'Practice') && (
              <Route
                path="/user/:id/edit"
                element={<EditUser></EditUser>}
              ></Route>
            )}
            {token.role === "Admin" && (
              <Route
                path="/user/create"
                element={<CreateUser></CreateUser>}
              ></Route>
            )}
            {token.role === 'Admin' && (
              <Route path="/reports" element={<Reports/>}></Route>
            )}
            <Route
              path="/logout"
              element={<Logout setToken={setToken}></Logout>}
            ></Route>
          </Routes>
        </Router>
      )}
      <div id="footer-spacer"></div>
      <footer className="text-center bg-light text-muted fixed-bottom mt-5">
        <div>
          &copy; 2023 Copyright:{" "}
          <a
            href="https://www.justcrowns.com/"
            target="_blank"
            rel="noreferrer"
            className="text-reset fw-bold"
          >
            Just Crowns
          </a>
        </div>
      </footer>
    </>
  );
}

export default App;
