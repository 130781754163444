import classNames from "classnames";

export default function Card({ title, description, padded = true, children }: { title?: string, description?: string, padded?: boolean, children: React.ReactNode}) {

  return (
    <div className={
      classNames('tw-bg-white tw-rounded tw-border tw-border-solid tw-border-slate-300', {
        'p-4': padded
      })
    }>
      {title && <h5 className="tw-text-slate-800 tw-mb-0">{title}</h5>}
      { description && <p className="tw-text-slate-500 tw-text-sm">{description}</p>}
      {children}
    </div>
  )
}