import { Form, InputGroup } from "react-bootstrap";
import { PatientCase } from "../../../types/model";
import { useDebouncedCallback } from "use-debounce";

export default function Notes({ patientCase, newNotes, setNewNotes, updateNotes, setSavingPatientId }: { patientCase: PatientCase, newNotes: any, setNewNotes: any, updateNotes: any, setSavingPatientId: any}) {

  const debouncedUpdateNotes = useDebouncedCallback((id) => {
    updateNotes(id);
    setSavingPatientId(null);
  }, 1000);

  const handleChange = (e: any) => {
    const newNotesCopy = structuredClone(newNotes);
    newNotesCopy[patientCase.id.toString()] = e.target.value;
    setNewNotes(newNotesCopy);
    setSavingPatientId(patientCase.id);

    // Debounce actually updating this.
    debouncedUpdateNotes(patientCase.id);
  };

  return (
    <td>
      <InputGroup className="d-inline-flex m-1">
        <Form.Control
          className="input-control"
          as="textarea"
          rows={1}
          value={
            newNotes[patientCase.id.toString()] ??
            patientCase.notes
          }
          onChange={handleChange}
        ></Form.Control>
      </InputGroup>
    </td>
  )
}