import { useEffect, useState } from "react";
import Metric, { MetricType } from "../../metric";
import { Axios } from "axios";
import { getReports } from "../../../api/reports";
import TableMetric from "../../metric/table";

export default function Reports() {
    const [metrics, setMetrics] = useState<MetricType[] | null>();
    const [loading, setLoading] = useState(true);

    const fetchReports = () => {
      getReports()
        .then((response) => {
          setMetrics(response.data);
        })
        .catch((error:Axios) => console.error(error))
        .finally(() => setLoading(false));
    }

    useEffect(() => {
      fetchReports();

      const intervalId = setInterval(() => {
        fetchReports();
      }, 10000);

      return () => clearInterval(intervalId);

    }, []);

    return (
        <>
            <div className="main-form container">
                <h1 className="mt-3">Reports</h1>

                <h2 className="mt-3">Metrics</h2>
                <div className="my-3 row">
                    {loading && <div className="col">Loading...</div>}

                    {metrics?.filter((metric) => metric.kind === 'stat')?.map((metric) => (
                        <div key={metric.id} className="col">
                          <Metric
                              id={metric.id}
                              title={metric.title}
                              description={metric.description}
                              data={metric.data}
                          />
                        </div>
                    ))}
                </div>

                <h2 className="mt-3">Data</h2>
                <div className="my-3">
                  {loading && <div className="col">Loading...</div>}
                  {metrics?.filter((metric) => metric.kind === 'table')?.map((metric => (
                    <div key={metric.id} className="my-3">
                      <TableMetric
                        id={metric.id}
                        title={metric.title}
                        description={metric.description}
                        data={metric.data}
                      />
                    </div>
                  )))}
                </div>
                

            </div>
        </>
    );
}
