import { FormikValues } from "formik";
import apiClient from "./api-client";

export function getPractices() {
  return apiClient.get("/practices");
}

export function getPractice(id: number) {
  return apiClient.get(`/practice?id=${id}`);
}

export function createPractice(values: FormikValues) {
  return apiClient.post("/practice", values);
}

export function destroyPractice(id: number) {
  return apiClient.delete(`/practice?id=${id}`);
}

export function updatePractice(values: FormikValues) {
  return apiClient.put("/practice", values);
}

export function getPracticesForDentist(dentistId: number) {
  return apiClient.get(`/practices/dentist?id=${dentistId}`);
}

export function assignDentistToPractice(practiceId: number, dentistId: number) {
  return apiClient.post(`/practice/dentist`, {
    practiceId,
    dentistId,
  });
}

export function assignDentistsToPractice(practiceId: number, dentistIds: number[]) {
  return apiClient.post(`/practice/dentists`, {
    practiceId,
    dentists: dentistIds,
  });
}

export function unassignDentistFromPractice(dentistId: number, practiceId: number) {
  return apiClient.delete(`/practice/dentist`, {
    data: {
      dentistId,
      practiceId,
    },
  });
}