import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { Dentist, Practice } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import { dateToSQLString, formatPhone } from "../../../util";
import useToken from "../../UseToken";
import { getPractice, unassignDentistFromPractice } from "../../../api/practices";
import { getDentistsForPractice } from "../../../api/dentists";

const ViewPractice = () => {
  const { id } = useParams();
  const { token } = useToken();
  const [practice, setPractice] = useState<Practice>({
    id: id ? parseInt(id) : 0,
    name: "",
    abbreviation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    mapLink: "",
    notes: "",
    phone: "",
    email: "",
    scanner: "",
    mailbox: false,
    defaultDeliveryMethod: "",
    created: dateToSQLString(new Date()),
  });
  const [fetchedDentists, setFetchedDentists] = useState<boolean>(false);
  const [dentists, setDentists] = useState<Dentist[]>([]);

  useEffect(() => {
    if (!practice.name) {
      getPractice(id as unknown as number).then((response) => {
        Object.keys(response.data).forEach((key) => {
          if (response.data[key] == null) {
            response.data[key] = "";
          }
        });
        setPractice(response.data);
      });
    }
    if (!fetchedDentists) {
      getDentistsForPractice(id as unknown as number)
        .then((response) => {
          setFetchedDentists(true);
          setDentists(response.data);
        })
        .catch((error: AxiosError) => {
          console.warn("error getting all dentists: ", error);
          toast("error getting all dentists: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  }, [fetchedDentists, id, practice.name]);

  const removeDentistFromPractice = (dentistId: number) => {
    unassignDentistFromPractice(dentistId, id as unknown as number)
      .then(() => {
        toast("Successfully removed dentist from practice", {
          type: "success",
          theme: "colored",
        });
        setDentists(
          dentists.filter((dentist) => {
            return dentist.id !== dentistId;
          })
        );
      })
      .catch((error: AxiosError) => {
        console.warn("error removing dentist from practice: ", error);
        toast("error removing dentist from practice: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">{practice.name}</h1>
        <div className="row my-3">
          <div className="col-md-6 offset-md-3">
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{practice.name}</td>
                </tr>
                <tr>
                  <th>Abbreviation</th>
                  <td>{practice.abbreviation}</td>
                </tr>
                <tr>
                  <th>Address Line 1</th>
                  <td>{practice.address1}</td>
                </tr>
                <tr>
                  <th>Address Line 2</th>
                  <td>{practice.address2}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{practice.city}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{practice.state}</td>
                </tr>
                <tr>
                  <th>Zip Code</th>
                  <td>{practice.zip}</td>
                </tr>
                <tr>
                  <th>Google Maps Link</th>
                  <td>
                    {practice.mapLink && (
                      <a
                        href={practice.mapLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View in Google Maps
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Notes</th>
                  <td>{practice.notes}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{formatPhone(practice.phone)}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{practice.email}</td>
                </tr>
                <tr>
                  <th>Scanner</th>
                  <td>{practice.scanner}</td>
                </tr>
                <tr>
                  <th>Mailbox</th>
                  <td>{practice.mailbox ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Default Delivery Method</th>
                  <td>{practice.defaultDeliveryMethod}</td>
                </tr>
                <tr>
                  <th>Created</th>
                  <td>{practice.created}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        {token && (token.role === "Admin" || token.role === "Case Manager") && (
          <Form className="row g-3">
            <Form.Group className="mt-3 col-md-6 offset-md-3">
              <a
                href={"/practice/" + id + "/edit"}
                className="btn btn-primary m-1"
              >
                Edit Practice
              </a>
            </Form.Group>
          </Form>
        )}
        <div className="row my-3">
          <h2 className="mt-3 col-md-6 offset-md-3">Dentists</h2>
          <div className="col-md-6 offset-md-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  {token &&
                    (token.role === "Admin" ||
                      token.role === "Case Manager") && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {dentists &&
                  dentists.map((dentist) => {
                    return (
                      <tr key={dentist.id}>
                        <td>
                          <a
                            href={"/dentist/" + dentist.id + "/view"}
                            className="text-reset"
                          >
                            {(dentist.docFirst ? dentist.docFirst + " " : "") +
                              dentist.docName}
                          </a>
                        </td>
                        {token &&
                          (token.role === "Admin" ||
                            token.role === "Case Manager") && (
                            <td>
                              <button
                                type="button"
                                onClick={() =>
                                  removeDentistFromPractice(dentist.id)
                                }
                                className="btn btn-danger m-1"
                              >
                                Remove Dentist from Practice
                              </button>
                            </td>
                          )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {token &&
              (token.role === "Admin" || token.role === "Case Manager") && (
                <a
                  href={"/practice/" + id + "/dentists"}
                  className="btn btn-primary m-1"
                >
                  Add Dentists
                </a>
              )}
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default ViewPractice;
