import { AxiosError } from "axios";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { UserToken } from "../../types/utility";
import * as formik from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { login } from "../../api/auth";

export default function Login(props: {
  setToken: (userToken: UserToken) => void;
}) {
  const schema = yup.object({
    id: yup.string().required("Required"),
    password: yup.string().required("Required"),
    rememberMe: yup.boolean(),
  });
  const { Formik } = formik;

  const handleOnSubmit = (values: formik.FormikValues) => {
    login(values)
      .then((response) => {
        props.setToken(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error logging in: ", error);
        toast("Error logging in", {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <div className="login-wrapper container">
      <h1 className="mt-3 col-md-6 offset-md-3">Just Crowns Case Management</h1>
      <Formik
        validationSchema={schema}
        onSubmit={handleOnSubmit}
        initialValues={{
          id: "",
          password: "",
          rememberMe: false,
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit} className="row g-3">
            <Form.Group controlId="id" className="mt-3 col-md-6 offset-md-3">
              <Form.Label>Username</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className="input-control"
                  type="text"
                  name="id"
                  value={values.id}
                  onChange={handleChange}
                  isInvalid={!!errors.id}
                />
                {touched.id && errors.id ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.id}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
            <Form.Group
              controlId="password"
              className="mt-3 col-md-6 offset-md-3"
            >
              <Form.Label>Password</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  className="input-control"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : null}{" "}
              </InputGroup>
            </Form.Group>
            <Form.Group className="mt-3 col-md-6 offset-md-3">
              <Form.Check
                id="remember-me"
                label="Remember me"
                type="checkbox"
                checked={values.rememberMe}
                onChange={(event) => {
                  handleChange({ ...event.target, target: {name: 'rememberMe', value: event.target.checked }});
                }}
              />
              <Button
                variant="primary"
                type="submit"
                className="submit-btn float-end"
              >
                Log In
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <ToastContainer></ToastContainer>
    </div>
  );
}
