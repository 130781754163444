import { PatientCase } from "../../../types/model"

export default function Flag({ patientCase }: { patientCase: PatientCase }) {
  const getFlags = (
    mill: boolean,
    remake: boolean,
    bio: boolean,
    rush: boolean
  ): string => {
    let displayValue = "";
    if (rush) {
      displayValue += "RUSH ";
    }
    if (mill) {
      displayValue += rush ? ", MILL" : "MILL";
    } else {
      if (remake) {
        displayValue += rush ? ", RMK" : "RMK";
        if (bio) {
          displayValue += ", BIO";
        }
      } else {
        if (bio) {
          displayValue += "BIO";
        }
      }
    }
    return displayValue;
  };
  
  return (
    <td className="column o">
      {getFlags(
        patientCase.millOnlyFlag,
        patientCase.remakeFlag,
        patientCase.bioCopyFlag,
        patientCase.rush
      )}
    </td>
  )
}