import { Table } from "react-bootstrap";
import { MetricType } from ".";
import Card from "../card/Card";

export default function TableMetric({ title, description, data }: MetricType) {

  return (

    <Card title={title} description={description}>
      <table className="tw-w-full">
          <thead className="tw-border-solid tw-border-b tw-border-slate-200 tw-text-sm tw-text-slate-600">
            <tr>
              {data?.headers?.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.rows?.map((row, index) => (
              <tr key={index} className="odd:tw-bg-slate-100">
                {row?.map((cell, cellIndex) => (
                  <td key={cellIndex} className="tw-px-2 tw-py-1 tw-text-slate-800">{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
    </Card>
  )
}