import { Form, InputGroup } from "react-bootstrap";
import { PatientCase } from "../../../types/model";

export default function Adjust({ patientCase, patientCases, setCases, forceUpdate, updateAdjustOpposing, updateAdjustProximal, updateAdjustBite }: { patientCase: PatientCase, patientCases: PatientCase[], setCases: (cases: PatientCase[]) => void, forceUpdate: () => void, updateAdjustOpposing: (id: number, opposingAdjustFlag: boolean) => void, updateAdjustProximal: (id: number, proximalAdjustFlag: boolean) => void, updateAdjustBite: (id: number, bite: boolean) => void}) {
  return (
    <td className="column adjust">
      <InputGroup>
        <Form.Check
          id={"adj-opp-" + patientCase.id}
          checked={Boolean(patientCase.opposingAdjustFlag)}
          onChange={(e) => {
            const otherCases: PatientCase[] =
              structuredClone(patientCases);
            const index: number = otherCases.findIndex(
              (pc: PatientCase) => pc.id === patientCase.id
            );
            const c: PatientCase = otherCases.splice(index, 1)[0];
            c.opposingAdjustFlag = !c.opposingAdjustFlag;
            otherCases.splice(index, 0, c);
            updateAdjustOpposing(c.id, c.opposingAdjustFlag);
            setCases(otherCases);
            forceUpdate();
          }}
        />
        &nbsp;
        <Form.Label
          htmlFor={"adj-opp-" + patientCase.id}
          className="me-1"
        >
          O
        </Form.Label>
        <Form.Check
          id={"adj-prox-" + patientCase.id}
          checked={Boolean(patientCase.proximalAdjustFlag)}
          onChange={(e) => {
            const otherCases: PatientCase[] =
              structuredClone(patientCases);
            const index: number = otherCases.findIndex(
              (pc: PatientCase) => pc.id === patientCase.id
            );
            const c: PatientCase = otherCases.splice(index, 1)[0];
            c.proximalAdjustFlag = !c.proximalAdjustFlag;
            otherCases.splice(index, 0, c);
            updateAdjustProximal(c.id, c.proximalAdjustFlag);
            setCases(otherCases);
            forceUpdate();
          }}
        />
        &nbsp;
        <Form.Label
          htmlFor={"adj-prox-" + patientCase.id}
          className="me-1"
        >
          P
        </Form.Label>
        <Form.Check
          id={"adj-bite-" + patientCase.id}
          checked={Boolean(patientCase.bite)}
          onChange={(e) => {
            const otherCases: PatientCase[] =
              structuredClone(patientCases);
            const index: number = otherCases.findIndex(
              (pc: PatientCase) => pc.id === patientCase.id
            );
            const c: PatientCase = otherCases.splice(index, 1)[0];
            c.bite = !c.bite;
            otherCases.splice(index, 0, c);
            updateAdjustBite(c.id, c.bite);
            setCases(otherCases);
            forceUpdate();
          }}
        />
        &nbsp;
        <Form.Label
          htmlFor={"adj-bite-" + patientCase.id}
          className="me-1"
        >
          B
        </Form.Label>
      </InputGroup>
    </td>
  )
}