import Card from "../card/Card";

export type MetricType = {
  id?: string;
  title: string;
  kind?: string;
  description: string;
  data: {
    value?: string;
    headers?: string[];
    rows?: string[][];
  };
}

export default function Metric({ title, description, data }: MetricType) {
  return (
    <Card title={title} description={description}>
      <p className="tw-text-5xl tw-text-slate-500 tw-mb-0">{data.value}</p>
    </Card>
  )
}