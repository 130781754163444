import classNames from "classnames"

export default function ColumnHeader({ sortable = false, name, onSort = null, sortName = null, sortDirection = null, children, ...props }: { sortable?: boolean | null, width?: string, name: string, sortName?: string | null, onSort?: ((key: string) => void) | null, sortDirection?: 'asc' | 'desc' | null, children: React.ReactNode }) {

  const handleClick = () => {
    if (sortable && onSort) {
      onSort(name)
    }
  }

  const SortIndicator = () => {
    return (
      <div className={classNames('sort', { active: name === sortName })}>
        { name !== sortName && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inactive">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
          </svg>
        )}
        { name === sortName && sortDirection === 'asc' && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
          </svg>
        )}
        { name === sortName && sortDirection === 'desc' && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        )}
      </div>
    )
  }

  return (
    <th className={classNames('column', name, { sortable })} onClick={handleClick} {...props}>
      {children}

      {sortable && <SortIndicator />}
    </th>
  )
}