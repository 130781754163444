export type LegendItem = {
  label: string;
  color: string;
  value: string | number;
}

export default function Legend({ items }: { items: LegendItem[] }) {

  return (
    <div className="legend">
      {items.map((item, index) => (
        <div key={index} className="d-flex align-items-center me-2">
          <div className={`legend-icon ${item.color !== '' ? item.color : 'default'}`}></div>
          <span className="ms-1">{item.label} ({item.value})</span>
        </div>
      ))}
    </div>
  )
}