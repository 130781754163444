import { PatientCase } from "../../../types/model"

export default function Patient({ patientCase }: { patientCase: PatientCase }) {
  return (
    <td>
      <a
        href={"/case/" + patientCase.id + "/view"}
        className="text-reset"
      >
        {patientCase.patientName}
      </a>
    </td>
  )
}