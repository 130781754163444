import { PatientCase } from "../../../types/model";

export default function Delivery({ patientCase, practices }: { patientCase: PatientCase, practices: any}) {
  return (
    <td
      className="column delivery"
      title={
        practices[patientCase.deliveryPracticeId.toString()]?.name
      }
    >
      <a
        href={
          "/practice/" + patientCase.deliveryPracticeId + "/view"
        }
        className="text-reset"
      >
        {
          practices[patientCase.deliveryPracticeId.toString()]
            ?.abr
        }
      </a>
    </td>
  )
}