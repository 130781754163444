import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import useToken from "./UseToken";
import UserMenu from "./user/UserMenu";

function NavigationBar() {
  const { token } = useToken();
  return (
    <Navbar bg="dark" expand="lg" variant="dark" sticky="top">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {token &&
            (token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <LinkContainer to="/cases">
                <Nav.Link>Cases</Nav.Link>
              </LinkContainer>
            )}
          {token && token.role === "Practice" && (
            <LinkContainer to="/cases/filter">
              <Nav.Link>Cases</Nav.Link>
            </LinkContainer>
          )}
          {token && token.role === "Practice" && (
            <LinkContainer to={"/user/"+token.id+"/edit"}>
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
          )}
          {token &&
            (token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <LinkContainer to="/dentists">
                <Nav.Link>Dentists</Nav.Link>
              </LinkContainer>
            )}
          {token &&
            (token.role === "Admin" ||
              token.role === "Case Manager" ||
              token.role === "Basic") && (
              <LinkContainer to="/practices">
                <Nav.Link>Practices</Nav.Link>
              </LinkContainer>
            )}
          {token && token.role === "Admin" && (
            <LinkContainer to="/prices">
              <Nav.Link>Prices</Nav.Link>
            </LinkContainer>
          )}
          {token && token.role === "Admin" && (
            <LinkContainer to="/reports">
              <Nav.Link>Reports</Nav.Link>
            </LinkContainer>
          )}
          {token && token.role === "Admin" && (
            <LinkContainer to="/users">
              <Nav.Link>Users</Nav.Link>
            </LinkContainer>
          )}
        </Nav>
      </Navbar.Collapse>
      <Navbar.Brand>
        { token && (
          <UserMenu name={token.id} />
        )}
      </Navbar.Brand>
    </Navbar>
  );
}
export default NavigationBar;
