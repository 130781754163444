import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { Dentist, Practice } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import { formatPhone } from "../../../util";
import useToken from "../../UseToken";
import { getDentist } from "../../../api/dentists";
import { getPracticesForDentist, unassignDentistFromPractice } from "../../../api/practices";

const ViewDentist = () => {
  const { id } = useParams();
  const [dentist, setDentist] = useState<Dentist>({
    id: id ? parseInt(id) : 0,
    status: "",
    docName: "",
    docFirst: "",
    assistName: "",
    docPhone: "",
    assistPhone: "",
    docEmail: "",
    notes: "",
  });
  const [practices, setPractices] = useState<Practice[]>([]);
  const [fetchedPractices, setFetchedPractices] = useState<boolean>(false);
  const { token } = useToken();

  useEffect(() => {
    if (!dentist.status && id) {
      getDentist(id as unknown as number).then((response) => {
        setDentist(response.data);
      });
    }
    if (!fetchedPractices) {
      getPracticesForDentist(id as unknown as number)
        .then((response): void => {
          setFetchedPractices(true);
          setPractices(response.data);
        });
    }
  });

  const removeDentistFromPractice = (practiceId: number) => {
    unassignDentistFromPractice(id as unknown as number, practiceId)
      .then(() => {
        toast("Successfully removed dentist from practice", {
          type: "success",
          theme: "colored",
        });
        setPractices(
          practices.filter((practice) => {
            return practice.id !== practiceId;
          })
        );
      })
      .catch((error: AxiosError) => {
        console.warn("error removing dentist from practice: ", error);
        toast("error removing dentist from practice: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Dr. {dentist.docName}</h1>
        <div className="row my-3">
          <div className="col-md-6 offset-md-3">
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>Dr. Name</th>
                  <td>
                    {(dentist.docFirst ? dentist.docFirst + " " : "") +
                      dentist.docName}
                  </td>
                </tr>
                <tr>
                  <th>Dr. Phone</th>
                  <td>
                    {dentist.docPhone ? formatPhone(dentist.docPhone) : ""}
                  </td>
                </tr>
                <tr>
                  <th>Dr. Email</th>
                  <td>{dentist.docEmail}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{dentist.status}</td>
                </tr>
                <tr>
                  <th>Assistant</th>
                  <td>{dentist.assistName}</td>
                </tr>
                <tr>
                  <th>Assistant Phone</th>
                  <td>
                    {dentist.assistPhone
                      ? formatPhone(dentist.assistPhone)
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Notes</th>
                  <td>{dentist.notes}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        {token && (token.role === "Admin" || token.role === "Case Manager") && (
          <Form className="row g-3">
            <Form.Group className="mt-3 col-md-6 offset-md-3">
              <a
                href={"/dentist/" + id + "/edit"}
                className="btn btn-primary m-1"
              >
                Edit Dentist
              </a>
            </Form.Group>
          </Form>
        )}
        <div className="row my-3">
          <h2 className="mt-3 col-md-6 offset-md-3">Practices</h2>
          <div className="col-md-6 offset-md-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  {token &&
                    (token.role === "Admin" ||
                      token.role === "Case Manager") && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {practices &&
                  practices.map((practice) => {
                    return (
                      <tr key={practice.id}>
                        <td>
                          <a
                            href={"/practice/" + practice.id + "/view"}
                            className="text-reset"
                          >
                            {practice.name}
                          </a>
                        </td>
                        {token &&
                          (token.role === "Admin" ||
                            token.role === "Case Manager") && (
                            <td>
                              <button
                                type="button"
                                onClick={() =>
                                  removeDentistFromPractice(practice.id)
                                }
                                className="btn btn-danger m-1"
                              >
                                Remove Dentist from Practice
                              </button>
                            </td>
                          )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {token &&
              (token.role === "Admin" || token.role === "Case Manager") && (
                <a
                  href={"/dentist/" + id + "/practice"}
                  className="btn btn-primary m-1"
                >
                  Add Practice
                </a>
              )}
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default ViewDentist;
