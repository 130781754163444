import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { COMPLETION_STATUSES } from "../../../consts";
import { useNavigate, useParams } from "react-router-dom";
import { Dentist, PatientCase, Practice } from "../../../types/model";
import { ToastContainer, toast } from "react-toastify";
import { DentistOption, PracticeOption } from "../../../types/utility";
import { Typeahead } from "react-bootstrap-typeahead";
import * as formik from "formik";
import * as yup from "yup";
import { getCase, updateCase } from "../../../api/cases";
import { getPracticesForDentist } from "../../../api/practices";
import { getActiveDentists } from "../../../api/dentists";
import { getPatientCaseRedirect } from "../../../util/NavRedirects";

const EditCase = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [patientCase, setPatientCase] = useState<PatientCase>({
    id: id ? parseInt(id) : 0,
    receivedTime: new Date(),
    dentistId: 0,
    billingPracticeId: 0,
    deliveryPracticeId: 0,
    patientName: "",
    units: "",
    adapter: "",
    shade: "",
    returnBy: "",
    completionStatus: "CREATED",
    puckPosition: "",
    deliveryMethod: "",
    trackingNumber: "DEL",
    engraving: "",
    notes: "",
    remakeFlag: false,
    bioCopyFlag: false,
    proximalAdjustFlag: false,
    opposingAdjustFlag: false,
    millOnlyFlag: false,
    rush: false,
    cost: '0',
    posterior: 0,
    anterior: 0,
    bite: false,
  });

  const schema = yup.object({
    id: yup.number().integer().required(),
    receivedTime: yup.date().required(),
    billingPracticeId: yup.number().required("Required").positive().integer(),
    deliveryPracticeId: yup.number().integer(),
    dentistId: yup.number().required("Required").positive().integer(),
    returnBy: yup.string(),
    patientName: yup.string().required("Required"),
    units: yup.string().required("Required"),
    adapter: yup.string().required("Required"),
    shade: yup.string().required("Required"),
    engraving: yup.string().required("Required"),
    deliveryMethod: yup.string().required("Required"),
    trackingNumber: yup.string(),
    completionStatus: yup.string(),
    puckPosition: yup.string(),
    notes: yup.string(),
    remakeFlag: yup.boolean(),
    bioCopyFlag: yup.boolean(),
    millOnlyFlag: yup.boolean(),
    rush: yup.boolean(),
    bite: yup.boolean(),
    anterior: yup.number().integer().required(),
    posterior: yup.number().integer().required(),
    proximalAdjustFlag: yup.boolean(),
    opposingAdjustFlag: yup.boolean(),
    selectedDentist: yup.array(
      yup.object({ id: yup.number(), name: yup.string() })
    ),
    selectedBillingPractice: yup.array(
      yup.object({
        id: yup.number(),
        name: yup.string(),
      })
    ),
    selectedDeliveryPractice: yup.array(
      yup.object({
        id: yup.number(),
        name: yup.string(),
      })
    ),
  });
  const { Formik } = formik;

  const [dentists, setDentists] = useState<DentistOption[]>([]);
  const [selectedDentist, setSelectedDentist] = useState<DentistOption[]>([]);
  const [practices, setPractices] = useState<PracticeOption[]>([]);
  const [selectedBillingPractice, setSelectedBillingPractice] = useState<
    PracticeOption[]
  >([]);
  const [selectedDeliveryPractice, setSelectedDeliveryPractice] = useState<
    PracticeOption[]
  >([]);
  const [initializedBillingPractice, setInitializedBillingPractice] =
    useState<boolean>(false);
  const [initializedDeliveryPractice, setInitializedDeliveryPractice] =
    useState<boolean>(false);

  useEffect(() => {
    if (!patientCase.patientName && id) {
      getCase(id)
      .then((response) => {
        Object.keys(response.data).forEach((key) => {
          if (response.data[key] == null) {
            response.data[key] = "";
          }
        });
        response.data.remakeFlag = Boolean(response.data.remakeFlag);
        response.data.bioCopyFlag = Boolean(response.data.bioCopyFlag);
        response.data.proximalAdjustFlag = Boolean(
          response.data.proximalAdjustFlag
        );
        response.data.opposingAdjustFlag = Boolean(
          response.data.opposingAdjustFlag
        );
        response.data.bite = Boolean(response.data.bite);
        response.data.millOnlyFlag = Boolean(response.data.millOnlyFlag);
        response.data.rush = Boolean(response.data.rush);
        setPatientCase(response.data);
      });
    }
    if (!practices.length && patientCase.dentistId) {
      getPracticesForDentist(patientCase.dentistId)
        .then((response) => {
          const practiceOptions = response?.data?.map((practice: Practice) => {
            return {
              id: practice.id,
              name: practice.name,
            };
          });
          setPractices(practiceOptions);
        });
    }
    if (!dentists.length) {
      getActiveDentists().then((response) => {
        const dentistOptions = response?.data?.map((dentist: Dentist) => {
          return {
            id: dentist.id,
            name:
              (dentist.docFirst ? dentist.docFirst + " " : "") +
              dentist.docName,
          };
        });
        setDentists(dentistOptions);
      });
    }
  });

  useEffect(() => {
    if (
      !initializedBillingPractice &&
      practices.length &&
      patientCase.billingPracticeId
    ) {
      setSelectedBillingPractice(
        practices.filter((p: PracticeOption) => {
          return p.id === patientCase.billingPracticeId;
        })
      );
      setInitializedBillingPractice(true);
    }
  }, [initializedBillingPractice, practices, patientCase]);

  useEffect(() => {
    if (
      !initializedDeliveryPractice &&
      practices.length &&
      patientCase.deliveryPracticeId
    ) {
      setSelectedDeliveryPractice(
        practices.filter((p: PracticeOption) => {
          return p.id === patientCase.deliveryPracticeId;
        })
      );
      setInitializedDeliveryPractice(true);
    }
  }, [initializedDeliveryPractice, practices, patientCase]);

  useEffect(() => {
    if (dentists.length && patientCase.dentistId) {
      const selectedDentist: DentistOption[] = dentists.filter(
        (dentist) => dentist.id === patientCase.dentistId
      );
      setSelectedDentist(selectedDentist);
    }
  }, [dentists, patientCase, initializedBillingPractice]);

  useEffect(() => {
    if (selectedDentist.length) {
      getPracticesForDentist(selectedDentist[0].id)
        .then((response) => {
          const practiceOptions = response?.data?.map((practice: Practice) => {
            return {
              id: practice.id,
              name: practice.name,
            };
          });
          if (practiceOptions?.length > 0) {
            setPractices(practiceOptions);
            if (practiceOptions?.length === 1) {
              setSelectedBillingPractice([practiceOptions[0]]);
              setSelectedDeliveryPractice([practiceOptions[0]]);
            }
          }
        });
    } else {
      setSelectedBillingPractice([]);
      setSelectedDeliveryPractice([]);
    }
  }, [selectedDentist]);

  useEffect(() => {
    if (
      selectedBillingPractice.length &&
      !selectedDeliveryPractice.length &&
      initializedBillingPractice
    ) {
      setSelectedDeliveryPractice(selectedBillingPractice);
    }
  }, [
    selectedBillingPractice,
    selectedDeliveryPractice,
    initializedBillingPractice,
  ]);

  const handleOnSubmit = (values: formik.FormikValues) => {
    values.dentistId = values.selectedDentist[0].id;
    values.deliveryPracticeId = values.selectedDeliveryPractice[0].id;
    values.billingPracticeId = values.selectedBillingPractice[0].id;

    updateCase(values)
      .then(() => {
        toast("Successfully updated case. Redirecting...", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });

        // Redirect based on how we got here.
        setTimeout(() => {
          navigate(getPatientCaseRedirect() || "/cases");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error updating patientCase: ", error);
        toast("error updating patientCase: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Update Case</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={{
            ...patientCase,
            selectedDentist,
            selectedBillingPractice,
            selectedDeliveryPractice,
          }}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="row g-3">
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedDentists"
              >
                <Form.Label>Select Dentist</Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-billing-dentist-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedDentist", selected);
                      setSelectedDentist(selected as DentistOption[]);
                    }}
                    options={dentists}
                    placeholder="Choose a dentist"
                    labelKey="name"
                    isInvalid={!!errors.selectedDentist}
                    selected={values.selectedDentist}
                    clearButton={true}
                  />
                  {touched.selectedDentist && errors.selectedDentist ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedDentist}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedBillingPractice"
              >
                <Form.Label>Select Billing Practice</Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-billing-practice-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedBillingPractice", selected);
                      setSelectedBillingPractice(selected as DentistOption[]);
                    }}
                    options={practices}
                    placeholder="Choose a practice"
                    labelKey="name"
                    isInvalid={!!errors.selectedBillingPractice}
                    selected={values.selectedBillingPractice}
                    clearButton={true}
                  />
                  {touched.selectedBillingPractice &&
                  errors.selectedBillingPractice ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedBillingPractice}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedDeliveryPractice"
              >
                <Form.Label>
                  Select Delivery Practice &#40;if different&#41;
                </Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-delivery-practice-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedDeliveryPractice", selected);
                    }}
                    options={practices}
                    placeholder="Choose a practice"
                    labelKey="name"
                    isInvalid={!!errors.selectedDeliveryPractice}
                    selected={values.selectedDeliveryPractice}
                    clearButton={true}
                  />
                  {touched.selectedDeliveryPractice &&
                  errors.selectedDeliveryPractice ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedDeliveryPractice}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="patientName"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Patient Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="patientName"
                    value={values.patientName}
                    onChange={handleChange}
                    isInvalid={!!errors.patientName}
                  />
                  {touched.patientName && errors.patientName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.patientName}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="units"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Teeth Numbers</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="units"
                    value={values.units}
                    onChange={handleChange}
                    isInvalid={!!errors.units}
                  />
                  {touched.units && errors.units ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.units}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="adapter"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Adapter</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="adapter"
                    value={values.adapter}
                    onChange={handleChange}
                    isInvalid={!!errors.adapter}
                  />
                  {touched.adapter && errors.adapter ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.adapter}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="shade"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Shade</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="shade"
                    value={values.shade}
                    onChange={handleChange}
                    isInvalid={!!errors.shade}
                  />
                  {touched.shade && errors.shade ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.shade}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="engraving"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Engraving</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="engraving"
                    value={values.engraving}
                    onChange={handleChange}
                    isInvalid={!!errors.engraving}
                  />
                  {touched.engraving && errors.engraving ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.engraving}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="anterior"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Anterior Units</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="number"
                    name="anterior"
                    value={values.anterior}
                    onChange={handleChange}
                    isInvalid={!!errors.anterior}
                  />
                  {touched.anterior && errors.anterior ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.anterior}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="posterior"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Posterior Units</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="number"
                    name="posterior"
                    value={values.posterior}
                    onChange={handleChange}
                    isInvalid={!!errors.posterior}
                  />
                  {touched.posterior && errors.posterior ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.posterior}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="completionStatus"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Completion Status</Form.Label>
                <InputGroup hasValidation>
                  <Form.Select
                    id="select-completion-status-dropdown"
                    onChange={(event: { target: { value: any } }) => {
                      setFieldValue("completionStatus", event.target.value);
                    }}
                    isInvalid={!!errors.completionStatus}
                    value={values.completionStatus}
                  >
                    {COMPLETION_STATUSES.map((status) => {
                      return <option value={status}>{status}</option>;
                    })}
                  </Form.Select>
                  {touched.completionStatus && errors.completionStatus ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.completionStatus}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Label>Delivery Method</Form.Label>
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-del"
                    type="radio"
                    name="deliveryMethod"
                    value="DEL"
                    checked={values.deliveryMethod === "DEL"}
                    onChange={handleChange}
                    isInvalid={!!errors.deliveryMethod}
                  />
                  <Form.Label htmlFor="radio-del" className="mx-3">
                    DEL
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-usps"
                    type="radio"
                    name="deliveryMethod"
                    value="USPS"
                    checked={values.deliveryMethod === "USPS"}
                    onChange={handleChange}
                    isInvalid={!!errors.deliveryMethod}
                  />
                  <Form.Label htmlFor="radio-usps" className="mx-3">
                    USPS
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-saver"
                    type="radio"
                    name="deliveryMethod"
                    value="UPS SAVER"
                    checked={values.deliveryMethod === "UPS SAVER"}
                    onChange={handleChange}
                    isInvalid={!!errors.deliveryMethod}
                  />
                  <Form.Label htmlFor="radio-saver" className="mx-3">
                    UPS SAVER
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-am"
                    type="radio"
                    name="deliveryMethod"
                    value="UPS AM"
                    checked={values.deliveryMethod === "UPS AM"}
                    onChange={handleChange}
                    isInvalid={!!errors.deliveryMethod}
                  />
                  <Form.Label htmlFor="radio-am" className="mx-3">
                    UPS AM
                  </Form.Label>
                </InputGroup>
                {touched.deliveryMethod && errors.deliveryMethod ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.deliveryMethod}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="trackingNumber"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Tracking Number</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="trackingNumber"
                    value={values.trackingNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.trackingNumber}
                  />
                  {touched.trackingNumber && errors.trackingNumber ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.trackingNumber}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="returnBy"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Return By</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="date"
                    name="returnBy"
                    value={values.returnBy}
                    onChange={handleChange}
                    isInvalid={!!errors.returnBy}
                  />
                  {touched.returnBy && errors.returnBy ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.returnBy}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="bio-copy-checkbox"
                  label="Bio Copy"
                  name="bioCopyFlag"
                  checked={values.bioCopyFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="remake-checkbox"
                  label="Remake"
                  name="remakeFlag"
                  checked={values.remakeFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="mill-only-checkbox"
                  label="Mill Only"
                  name="millOnlyFlag"
                  checked={values.millOnlyFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="rush-checkbox"
                  label="Rush"
                  name="rush"
                  checked={values.rush}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="opposing-checkbox"
                  label="Adjust Opposing"
                  name="opposingAdjustFlag"
                  checked={values.opposingAdjustFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="proximal-checkbox"
                  label="Adjust Proximal"
                  name="proximalAdjustFlag"
                  checked={values.proximalAdjustFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="bite-checkbox"
                  label="Adjust Bite"
                  name="bite"
                  checked={values.bite}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                controlId="notes"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Notes &#40;Optional&#41;</Form.Label>
                <Form.Control
                  className="input-control"
                  as="textarea"
                  rows={3}
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Update Case
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default EditCase;
