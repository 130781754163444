import { FormikValues } from "formik";
import apiClient from "./api-client";

export function getUsers() {
  return apiClient.get("/users");
}

export function getUserRoles() {
  return apiClient.get("/user/roles");
}

export function createUser(values: FormikValues) {
  return apiClient.post("/user", values);
}

export function getUserRole(id: number) {
  return apiClient.get(`/user/role?id=${id}`);
}

export function destroyUser(id: number) {
  return apiClient.delete(`/user?id=${id}`);
}

export function assignRoleToUser(id: number, role: string) {
  return apiClient.patch("/user/role", { id, role });
}

export function updateUserPassword(id: number, password: string) {
  return apiClient.patch("/user/password", { id, password});
}