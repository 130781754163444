const patientCaseKey = 'patient_case_redirect';

export function savePatientCaseRedirect(path: string) {

  localStorage.setItem(patientCaseKey, path);
}

export function getPatientCaseRedirect() {

  return localStorage.getItem(patientCaseKey);
}