import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as formik from "formik";
import * as yup from "yup";
import useToken from "../../UseToken";
import { assignRoleToUser, getUserRole, getUserRoles, updateUserPassword } from "../../../api/users";

const EditUser = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const [initialRole, setInitialRole] = useState<string>("");
  const { token } = useToken();
  const roleSchema = yup.object({
    role: yup.string().required("Required"),
  });
  const passwordSchema = yup.object({
    password: yup.string().required("Required"),
  });
  const { Formik } = formik;

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialRole) {
      getUserRole(id as unknown as number).then((response) => {
        setInitialRole(response.data);
      });
    }
    if (!roles.length) {
      getUserRoles()
        .then((response) => {
          setRoles(response.data.filter((role: string) => role !== "Practice"));
        })
        .catch((error: AxiosError) => {
          console.warn("error fetching roles: ", error);
          toast("error fetching roles: " + error.message, {
            type: "error",
            theme: "colored",
            autoClose: 1500,
          });
        });
    }
  });

  const handleOnPasswordSubmit = (values: formik.FormikValues) => {
    updateUserPassword(id as unknown as number, values.password)
      .then(() => {
        if (token && token.role === "Admin") {
          toast(
            "Successfully updated user password. Redirecting to user home",
            {
              type: "success",
              theme: "colored",
              autoClose: 1500,
            }
          );
          setTimeout(() => {
            navigate("/users");
          }, 1500);
        } else {
          toast("Successfully updated password. Redirecting to case home", {
            type: "success",
            theme: "colored",
            autoClose: 1500,
          });
          setTimeout(() => {
            navigate("/cases/filter");
          }, 1500);
        }
      })
      .catch((error: AxiosError) => {
        console.warn("error updating user password: ", error);
        toast("error updating user password: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const handleOnRoleSubmit = (values: formik.FormikValues) => {
    assignRoleToUser(id as unknown as number, values.role)
      .then(() => {
        toast("Successfully updated user role. Redirecting to user home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/users");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error updating user role: ", error);
        toast("error updating user role: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Editing User: {id}</h1>
        <h2 className="mt-3 col-md-6 offset-md-3">Update Password</h2>
        <Formik
          validationSchema={passwordSchema}
          onSubmit={handleOnPasswordSubmit}
          initialValues={{
            password: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit} className="row g-3">
              <Form.Group
                controlId="password"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  {touched.password && errors.password ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Update Password
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>

      {initialRole && initialRole !== "Practice" && (
        <div className="main-form container">
          <h2 className="mt-3 col-md-6 offset-md-3">Update Role</h2>
          <Formik
            validationSchema={roleSchema}
            onSubmit={handleOnRoleSubmit}
            initialValues={{
              role: initialRole,
            }}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form onSubmit={handleSubmit} className="row g-3">
                <Form.Group
                  controlId="role"
                  className="mt-3 col-md-6 offset-md-3"
                >
                  <Form.Label>Role</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select
                      aria-label="Default select example"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      isInvalid={!!errors.role}
                    >
                      {roles &&
                        roles.map((role) => {
                          return <option value={role}>{role}</option>;
                        })}
                    </Form.Select>
                    {touched.role && errors.role ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.role}
                      </Form.Control.Feedback>
                    ) : null}
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mt-3 col-md-6 offset-md-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="submit-btn float-end"
                  >
                    Update Role
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <ToastContainer></ToastContainer>
    </>
  );
};

export default EditUser;
