import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { AxiosError } from "axios";
import { Dentist, Practice } from "../../../types/model";
import { DentistOption, PracticeOption } from "../../../types/utility";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as formik from "formik";
import * as yup from "yup";
import { determineReturnBy } from "../../../util";
import { getActiveDentists } from "../../../api/dentists";
import { getPracticesForDentist } from "../../../api/practices";
import { createCase } from "../../../api/cases";

const CreateCase = () => {
  const navigate = useNavigate();

  const schema = yup.object({
    returnBy: yup.string(),
    caseInfo: yup
      .string()
      .matches(
        /^\w+ \w+ ([0-9]+([=,^][0-9])*)* [A-Z0-9]+$/,
        "Enter case info like this: John Doe 3=5,7^9 A3"
      )
      .required("Required"),
    selectedDeliveryMethod: yup.string(),
    notes: yup.string(),
    remakeFlag: yup.boolean(),
    bioCopyFlag: yup.boolean(),
    millOnly: yup.boolean(),
    selectedDentist: yup.array(
      yup.object({ id: yup.number(), name: yup.string() })
    ),
    selectedBillingPractice: yup.array(
      yup.object({
        id: yup.number(),
        name: yup.string(),
      })
    ),
    selectedDeliveryPractice: yup.array(
      yup.object({
        id: yup.number(),
        name: yup.string(),
      })
    ),
  });
  const { Formik } = formik;

  const [dentists, setDentists] = useState<DentistOption[]>([]);
  const [selectedDentist, setSelectedDentist] = useState<DentistOption[]>([]);
  const [practices, setPractices] = useState<PracticeOption[]>([]);
  const [practiceDefaults, setPracticeDefaults] = useState<{
    [key: number]: string;
  }>({});
  const [selectedBillingPractice, setSelectedBillingPractice] = useState<
    PracticeOption[]
  >([]);
  const [selectedDeliveryPractice, setSelectedDeliveryPractice] = useState<
    PracticeOption[]
  >([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] =
    useState<string>("");
  const [engraving, setEngraving] = useState<string>("");

  useEffect(() => {
    if (!dentists?.length) {
      getActiveDentists()
        .then((response) => {
          const dentistOptions = response?.data?.map((dentist: Dentist) => {
            return {
              id: dentist.id,
              name:
                (dentist.docFirst ? dentist.docFirst + " " : "") +
                dentist.docName,
            };
          });
          setDentists(dentistOptions);
        });
    }
  });

  useEffect(() => {
    if (selectedDentist.length) {
      getPracticesForDentist(selectedDentist[0].id)
        .then((response) => {
          const practiceOptions: PracticeOption[] = [];
          const practiceDefaultList: { [key: number]: string } = {};
          response?.data?.forEach((practice: Practice) => {
            practiceOptions.push({
              id: practice.id,
              name: practice.name,
            });
            practiceDefaultList[practice.id] = practice.defaultDeliveryMethod;
          });
          setPracticeDefaults(practiceDefaultList);
          if (practiceOptions.length > 0) {
            setPractices(practiceOptions);
            if (practiceOptions.length === 1) {
              setSelectedBillingPractice([practiceOptions[0]]);
              setSelectedDeliveryPractice([practiceOptions[0]]);
            } else {
              setSelectedBillingPractice([]);
              setSelectedDeliveryPractice([]);
            }
          }
        });
    }
  }, [selectedDentist]);

  useEffect(() => {
    if (selectedBillingPractice.length && practices.length > 1) {
      setSelectedDeliveryPractice(selectedBillingPractice);
    }
  }, [selectedBillingPractice, practices]);

  useEffect(() => {
    if (
      selectedDeliveryPractice.length &&
      practiceDefaults[selectedDeliveryPractice[0].id]
    ) {
      setSelectedDeliveryMethod(
        practiceDefaults[selectedDeliveryPractice[0].id]
      );
    }
  }, [selectedDeliveryPractice, practiceDefaults]);

  const createEngravingFromCaseInfo = (caseInfo: string) => {
    const words = caseInfo.split(" ");
    if (words.length > 1) {
      setEngraving(words[1].slice(0, 3).toUpperCase());
    }
  };

  const handleOnSubmit = (values: formik.FormikValues) => {
    if (!values.selectedDentist.length) {
      toast("You must select a dentist to create a case", {
        type: "error",
        theme: "colored",
        autoClose: 3000,
      });
      return;
    }
    if (!values.selectedBillingPractice.length) {
      toast("You must select a billing practice to create a case", {
        type: "error",
        theme: "colored",
        autoClose: 3000,
      });
      return;
    }
    if (!selectedDeliveryMethod) {
      toast("You must select a delivery method to create a case", {
        type: "error",
        theme: "colored",
        autoClose: 3000,
      });
      return;
    }
    if (!engraving) {
      toast("You must enter an engraving to create a case", {
        type: "error",
        theme: "colored",
        autoClose: 3000,
      });
      return;
    }
    values.engraving = engraving;
    values.deliveryMethod = selectedDeliveryMethod;
    values.dentistId = values.selectedDentist[0].id;
    values.billingPracticeId = values.selectedBillingPractice[0].id;
    if (!values.selectedDeliveryPractice.length) {
      values.deliveryPracticeId = values.billingPracticeId;
    } else {
      values.deliveryPracticeId = values.selectedDeliveryPractice[0].id;
    }
    createCase(values)
      .then(() => {
        toast("Successfully created case. Redirecting to case home", {
          type: "success",
          theme: "colored",
          autoClose: 1500,
        });
        setTimeout(() => {
          navigate("/cases");
        }, 1500);
      })
      .catch((error: AxiosError) => {
        console.warn("error creating patientCase: ", error);
        toast("error creating patientCase: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div className="main-form container">
        <h1 className="mt-3 col-md-6 offset-md-3">Create Case</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleOnSubmit}
          initialValues={{
            billingPracticeId: 0,
            deliveryPracticeId: 0,
            dentistId: 0,
            returnBy: determineReturnBy(),
            caseInfo: "",
            notes: "",
            remakeFlag: false,
            bioCopyFlag: false,
            millOnlyFlag: false,
            selectedDentist,
            selectedBillingPractice,
            selectedDeliveryPractice,
            rush: false,
          }}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="row g-3">
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedDentists"
              >
                <Form.Label>Select Dentist</Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-billing-dentist-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedDentist", selected);
                      setSelectedDentist(selected as DentistOption[]);
                    }}
                    options={dentists ?? []}
                    placeholder="Choose a dentist"
                    labelKey="name"
                    isInvalid={!!errors.selectedDentist}
                    selected={values.selectedDentist}
                    clearButton={true}
                  />
                  {touched.selectedDentist && errors.selectedDentist ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedDentist}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedBillingPractice"
              >
                <Form.Label>Select Billing Practice</Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-billing-practice-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedBillingPractice", selected);
                      setSelectedBillingPractice(selected as PracticeOption[]);
                    }}
                    options={practices}
                    placeholder="Choose a practice"
                    labelKey="name"
                    isInvalid={!!errors.selectedBillingPractice}
                    selected={values.selectedBillingPractice}
                    disabled={!selectedDentist.length}
                    clearButton={true}
                  />
                  {touched.selectedBillingPractice &&
                  errors.selectedBillingPractice ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedBillingPractice}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                className="mt-3 col-md-6 offset-md-3"
                controlId="selectedDeliveryPractice"
              >
                <Form.Label>
                  Select Delivery Practice &#40;if different&#41;
                </Form.Label>
                <InputGroup hasValidation>
                  <Typeahead
                    id="select-delivery-practice-dropdown"
                    onChange={(selected) => {
                      setFieldValue("selectedDeliveryPractice", selected);
                    }}
                    options={practices}
                    placeholder="Choose a practice"
                    labelKey="name"
                    isInvalid={!!errors.selectedDeliveryPractice}
                    selected={values.selectedDeliveryPractice}
                    disabled={!selectedDentist.length}
                    clearButton={true}
                  />
                  {touched.selectedDeliveryPractice &&
                  errors.selectedDeliveryPractice ? (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.selectedDeliveryPractice}</>
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="caseInfo"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Case Info</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="caseInfo"
                    value={values.caseInfo}
                    onChange={(e) => {
                      createEngravingFromCaseInfo(e.target.value);
                      handleChange(e);
                    }}
                    isInvalid={!!errors.caseInfo}
                  />
                  {touched.caseInfo && errors.caseInfo ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.caseInfo}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="engraving"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Engraving</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="text"
                    name="engraving"
                    value={engraving}
                    onChange={(e) => {
                      setEngraving(e.target.value.toUpperCase());
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Label>Delivery Method</Form.Label>
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-del"
                    type="radio"
                    name="selectedDeliveryMethod"
                    value="DEL"
                    checked={selectedDeliveryMethod === "DEL"}
                    onChange={(e) => {
                      setSelectedDeliveryMethod(e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="radio-del" className="mx-3">
                    DEL
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-usps"
                    type="radio"
                    name="selectedDeliveryMethod"
                    value="USPS"
                    checked={selectedDeliveryMethod === "USPS"}
                    onChange={(e) => {
                      setSelectedDeliveryMethod(e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="radio-usps" className="mx-3">
                    USPS
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-saver"
                    type="radio"
                    name="selectedDeliveryMethod"
                    value="UPS SAVER"
                    checked={selectedDeliveryMethod === "UPS SAVER"}
                    onChange={(e) => {
                      setSelectedDeliveryMethod(e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="radio-saver" className="mx-3">
                    UPS SAVER
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-md-6 offset-md-3">
                <InputGroup hasValidation>
                  <Form.Check
                    id="radio-am"
                    type="radio"
                    name="deliveryMethod"
                    value="UPS AM"
                    checked={selectedDeliveryMethod === "UPS AM"}
                    onChange={(e) => {
                      setSelectedDeliveryMethod(e.target.value);
                    }}
                  />
                  <Form.Label htmlFor="radio-am" className="mx-3">
                    UPS AM
                  </Form.Label>
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="returnBy"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Return By</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    className="input-control"
                    type="date"
                    name="returnBy"
                    value={values.returnBy}
                    onChange={handleChange}
                    isInvalid={!!errors.returnBy}
                  />
                  {touched.returnBy && errors.returnBy ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.returnBy}
                    </Form.Control.Feedback>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="bio-copy-checkbox"
                  label="Bio Copy"
                  name="bioCopyFlag"
                  checked={values.bioCopyFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="remake-checkbox"
                  label="Remake"
                  name="remakeFlag"
                  checked={values.remakeFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="mill-only-checkbox"
                  label="Mill Only"
                  name="millOnlyFlag"
                  checked={values.millOnlyFlag}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Form.Check
                  type="checkbox"
                  id="rush-checkbox"
                  label="Rush"
                  name="rush"
                  checked={values.rush}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                controlId="notes"
                className="mt-3 col-md-6 offset-md-3"
              >
                <Form.Label>Notes &#40;Optional&#41;</Form.Label>
                <Form.Control
                  className="input-control"
                  as="textarea"
                  rows={3}
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mt-3 col-md-6 offset-md-3">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn float-end"
                >
                  Create Case
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default CreateCase;
