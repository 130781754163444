import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { Dentist, PatientCase, Practice } from "../../../types/model";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  abbreviateFirstName,
  convertToUserTimezone,
  dateToMonthAndDay,
  dateToSQLString,
  dateToShortTimestamp,
  getClassesForStatus,
  getTrackingLink,
} from "../../../util";
import useToken from "../../UseToken";
import { UserToken } from "../../../types/utility";
import { ToastContainer, toast } from "react-toastify";
import { getPractices } from "../../../api/practices";
import { destroyCase, getCasesBetweenDates, getCasesForPractice } from "../../../api/cases";
import { getDentists, getDentistsForPractice } from "../../../api/dentists";
import CopyLabel from "../../cases/columns/CopyLabel";
import { savePatientCaseRedirect } from "../../../util/NavRedirects";

const FilterCases = () => {
  const [patientCases, setCases] = useState<PatientCase[]>([]);
  const [filteredCases, setFilteredCases] = useState<PatientCase[]>([]);
  const [patientFilter, setPatientFilter] = useState<string>("");
  const [dentistFilter, setDentistFilter] = useState<number>();
  const [billingPracticeFilter, setBillingPracticeFilter] = useState<number>(0);
  const [deliveryPracticeFilter, setDeliveryPracticeFilter] =
    useState<number>(0);
  const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));
  const [startDate, setStartDate] = useState<string>(
    dateToSQLString(thirtyDaysAgo)
  );
  const [endDate, setEndDate] = useState<string>("");
  const [fetchedCases, setFetchedCases] = useState<boolean>(false);
  const [fetchedDentists, setFetchedDentists] = useState<boolean>(false);
  const [dentists, setDentists] = useState<{
    [key: string]: { niceName: string; lastName: string; status: string };
  }>({});
  const [fetchedPractices, setFetchedPractices] = useState<boolean>(false);
  const [practices, setPractices] = useState<{
    [key: string]: { abr: string; name: string };
  }>({});
  const { token } = useToken();
  
  
  // Save that we got here so we can redirect back if we need to after editing a patient case.
  useEffect(() => {
    savePatientCaseRedirect(window.location.pathname);
  });


  useEffect(() => {
    if (!fetchedPractices && token && token.role !== "Practice") {
      setFetchedPractices(true);
      getAllPractices();
    }
  }, [fetchedPractices, token]);

  const getAllPractices = () => {
    
    getPractices()
      .then((response) => {
        const practiceMap: { [key: string]: { abr: string; name: string } } =
          {};
        response.data.forEach((practice: Practice) => {
          practiceMap[practice.id.toString()] = {
            abr: practice.abbreviation,
            name: practice.name,
          };
        });
        setPractices(practiceMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all practices: ", error);
        toast("error getting all practices: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const sortPracticesByAbbreviation = (a: string, b: string): number => {
    return practices[a].abr.localeCompare(practices[b].abr);
  };

  const getCasesByDates = () => {
    if (!startDate) {
      toast("Select a start date to filter by date", {
        type: "error",
        theme: "colored",
        autoClose: 1500,
      });
      return;
    }

    getCasesBetweenDates(startDate, endDate)
      .then((response) => {
        setCases(response.data);
        setFilteredCases(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all cases: ", error);
        toast("error getting all cases: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const getPracticeCases = (t: UserToken) => {
    getCasesForPractice(t.practice?.id, startDate, endDate)
      .then((response) => {
        setCases(response.data);
        setFilteredCases(response.data);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting cases by practice: ", error);
        toast("error getting cases by practice: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    if (!fetchedCases && token) {
      if (token.role === "Practice" && token.practice?.id) {
        getPracticeCases(token);
        setFetchedCases(true);
      } else if (token.role !== "Practice") {
        getCasesByDates();
        setFetchedCases(true);
      }
    }
  }, [fetchedCases, getPracticeCases, token]);

  useEffect(() => {
    if (!fetchedDentists && token) {
      if (token.role === "Practice" && token.practice?.id) {
        setFetchedDentists(true);
        getPracticeDentists(token);
      } else if (token.role !== "Practice") {
        setFetchedDentists(true);
        getAllDentists();
      }
    }
  }, [fetchedDentists, token]);

  const getAllDentists = () => {
    getDentists()
      .then((response) => {
        const dentistMap: {
          [key: string]: { niceName: string; lastName: string; status: string };
        } = {};
        response.data.forEach((dentist: Dentist) => {
          dentistMap[dentist.id.toString()] = {
            niceName:
              (dentist.docFirst
                ? dentist.docFirst.substring(0, 1) + ". "
                : "") + dentist.docName,
            lastName: dentist.docName,
            status: dentist.status,
          };
        });
        dentistMap["0"] = {
          lastName: "",
          status: "",
          niceName: "Select to filter",
        };
        setDentists(dentistMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all dentists: ", error);
        toast("error getting all dentists: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const getPracticeDentists = (t: UserToken) => {
    getDentistsForPractice(t.practice?.id ?? 0)
      .then((response) => {
        const dentistMap: {
          [key: string]: { niceName: string; lastName: string; status: string };
        } = {};
        response.data.forEach((dentist: Dentist) => {
          dentistMap[dentist.id.toString()] = {
            niceName:
              (dentist.docFirst
                ? dentist.docFirst.substring(0, 1) + ". "
                : "") + dentist.docName,
            lastName: dentist.docName,
            status: dentist.status,
          };
        });
        dentistMap["0"] = {
          lastName: "",
          status: "",
          niceName: "Select to filter",
        };
        setDentists(dentistMap);
      })
      .catch((error: AxiosError) => {
        console.warn("error getting all dentists: ", error);
        toast("error getting all dentists: " + error.message, {
          type: "error",
          theme: "colored",
          autoClose: 1500,
        });
      });
  };

  const deleteCase = (id: string) => {
    destroyCase(id)
      .then(() => {
        toast("Successfully deleted case", {
          type: "success",
          theme: "colored",
        });
        setCases(
          patientCases.filter((c) => {
            return c.id !== parseInt(id);
          })
        );
      });
  };

  useEffect(() => {
    let data = patientCases;

    if (patientFilter) {
      if (token && token.role === "Practice") {
        data = data.filter((c) => {
          return abbreviateFirstName(c.patientName)
            .toLowerCase()
            .includes(patientFilter.toLowerCase());
        });
      } else {
        data = data.filter((c) => {
          return c.patientName
            .toLowerCase()
            .includes(patientFilter.toLowerCase());
        });
      }
    }

    if (dentistFilter) {
      data = data.filter((c) => {
        return dentistFilter === c.dentistId;
      });
    }

    if (billingPracticeFilter) {
      data = data.filter((c) => {
        return billingPracticeFilter === c.billingPracticeId;
      });
    }

    if (deliveryPracticeFilter) {
      data = data.filter((c) => {
        return deliveryPracticeFilter === c.deliveryPracticeId;
      });
    }
    setFilteredCases(data);
  }, [
    patientCases,
    patientFilter,
    dentistFilter,
    billingPracticeFilter,
    deliveryPracticeFilter,
    token,
  ]);

  const getFlags = (
    mill: boolean,
    remake: boolean,
    bio: boolean,
    rush: boolean
  ): string => {
    let displayValue = "";
    if (rush) {
      displayValue += "RUSH ";
    }
    if (mill) {
      displayValue += rush ? " MILL" : "MILL";
    } else {
      if (remake) {
        displayValue += "RMK";
        if (bio) {
          displayValue += ", BIO";
        }
      } else {
        if (bio) {
          displayValue += "BIO";
        }
      }
    }
    return displayValue;
  };

  return (
    <div className="font-size-small">
      <div className="col">
        {token && token.role === "Practice" && (
          <div className="row">
            <div className="col-md-3 my-1">
              Call or Text Lab:{" "}
              <a href="tel:+12084954248">
                <strong>208-495-4248</strong>
              </a>
            </div>
            <div className="col-md-3 my-1">
              Email Lab:{" "}
              <a
                href={
                  "mailto:shared@justcrowns.com?subject=LMS%20Email%20from%20" +
                  token.practice?.abr
                }
              >
                <strong>shared@justcrowns.com</strong>
              </a>
            </div>
            <div className="col-md-3 my-1">
              Call or Text Randy:{" "}
              <a href="tel:+12089825011">
                <strong>208-982-5011</strong>
              </a>
            </div>
            <div className="col-md-3 my-1">
              Email Randy:{" "}
              <a
                href={
                  "mailto:randy@justcrowns.com?subject=LMS%20Email%20to%20Randy%20from%20" +
                  token.practice?.abr
                }
              >
                <strong>randy@justcrowns.com</strong>
              </a>
            </div>
          </div>
        )}
        <div className="row my-3">
          <div className="col-md-3">
            <InputGroup>
              <Form.Label
                htmlFor="start-date"
                className="input-group-text no-margin-bottom"
              >
                Start Date
              </Form.Label>
              <Form.Control
                id="start-date"
                type="date"
                name="startDate"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </InputGroup>
          </div>
          <div className="col-md-3">
            <InputGroup>
              <Form.Label
                htmlFor="end-date"
                className="input-group-text no-margin-bottom"
              >
                End Date
              </Form.Label>
              <Form.Control
                id="end-date"
                type="date"
                name="endDate"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </InputGroup>
          </div>
          <div className="col-md-2">
            <Button
              type="button"
              onClick={() => {
                token?.role === "Practice"
                  ? getPracticeCases(token)
                  : getCasesByDates();
              }}
            >
              Filter by Dates
            </Button>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Received</th>
              <th>Dentist</th>
              <th>Patient</th>
              <th>Teeth</th>
              <th>Shade</th>
              <th>Flags</th>
              {token && token.role !== "Practice" && <th>BILL</th>}
              {token && token.role !== "Practice" && <th>DEL</th>}
              {token && token.role !== "Practice" && <th>Due</th>}
              <th>Track</th>
              <th>Status</th>
              <th>Ant</th>
              <th>Post</th>
              <th>Fee</th>
              <th>Label</th>
              {token &&
                      (token.role === "Admin" ||
                        token.role === "Case Manager") && (
                <th style={{ width: "110px"}}>Actions</th>
              )}
            </tr>
            <tr>
              <th></th>
              <th>
                <InputGroup>
                  <Form.Select
                    onChange={(e) => {
                      if (e.target.value)
                        setDentistFilter(parseInt(e.target.value));
                    }}
                    value={dentistFilter?.toString()}
                  >
                    {dentists &&
                      Object.keys(dentists).map((dentistId) => {
                        return (
                          <option value={dentistId}>
                            {dentists[dentistId].niceName}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <Button
                    title="Clear filter"
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      setDentistFilter(0);
                    }}
                  >
                    X
                  </Button>
                </InputGroup>
              </th>
              <th>
                <InputGroup>
                  <Form.Control
                    id="patient-filter"
                    className="form-control"
                    placeholder="Type to filter"
                    value={patientFilter}
                    onChange={(e) => {
                      setPatientFilter(e.target.value);
                    }}
                  ></Form.Control>
                  <Button
                    title="Clear filter"
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      setPatientFilter("");
                    }}
                  >
                    X
                  </Button>
                </InputGroup>
              </th>
              <th></th>
              <th></th>
              <th></th>
              {token && token.role !== "Practice" && (
                <th>
                  <InputGroup>
                    <Form.Select
                      onChange={(e) => {
                        if (e.target.value)
                          setBillingPracticeFilter(parseInt(e.target.value));
                      }}
                      value={billingPracticeFilter?.toString()}
                    >
                      <option value={0} disabled>
                        Select to filter
                      </option>
                      {practices &&
                        Object.keys(practices)
                          .sort(sortPracticesByAbbreviation)
                          .map((practiceId) => {
                            return (
                              <option value={practiceId}>
                                {practices[practiceId].abr}
                              </option>
                            );
                          })}
                    </Form.Select>
                    <Button
                      title="Clear filter"
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        setBillingPracticeFilter(0);
                      }}
                    >
                      X
                    </Button>
                  </InputGroup>
                </th>
              )}
              {token && token.role !== "Practice" && (
                <th>
                  <InputGroup>
                    <Form.Select
                      onChange={(e) => {
                        if (e.target.value)
                          setDeliveryPracticeFilter(parseInt(e.target.value));
                      }}
                      value={deliveryPracticeFilter?.toString()}
                    >
                      <option value={0} disabled>
                        Select to filter
                      </option>
                      {practices &&
                        Object.keys(practices)
                          .sort(sortPracticesByAbbreviation)
                          .map((practiceId) => {
                            return (
                              <option value={practiceId}>
                                {practices[practiceId].abr}
                              </option>
                            );
                          })}
                    </Form.Select>
                    <Button
                      title="Clear filter"
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        setDeliveryPracticeFilter(0);
                      }}
                    >
                      X
                    </Button>
                  </InputGroup>
                </th>
              )}
              {token && token.role !== "Practice" && <th></th>}
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              {token &&
                      (token.role === "Admin" ||
                        token.role === "Case Manager") && (
                <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredCases &&
              filteredCases.map((patientCase) => {
                return (
                  <tr key={patientCase.id}>
                    <td>
                      {dateToShortTimestamp(convertToUserTimezone(new Date(patientCase.receivedTime)))}
                    </td>
                    <td>
                      {token && token.role !== "Practice" && (
                        <a
                          href={"/dentist/" + patientCase.dentistId + "/view"}
                          className="text-reset"
                        >
                          {dentists[patientCase.dentistId.toString()]?.niceName}
                        </a>
                      )}
                      {token &&
                        token.role === "Practice" &&
                        dentists[patientCase.dentistId.toString()]?.niceName}
                    </td>
                    <td>
                      {token && token.role !== "Practice" && (
                        <a
                          href={"/case/" + patientCase.id + "/view"}
                          className="text-reset"
                        >
                          {patientCase.patientName}
                        </a>
                      )}
                      {token &&
                        token.role === "Practice" &&
                        abbreviateFirstName(patientCase.patientName)}
                    </td>
                    <td>{patientCase.units}</td>
                    <td>{patientCase.shade}</td>
                    <td>
                      {getFlags(
                        patientCase.millOnlyFlag,
                        patientCase.remakeFlag,
                        patientCase.bioCopyFlag,
                        patientCase.rush
                      )}
                    </td>
                    {token && token.role !== "Practice" && (
                      <td
                        title={
                          practices[patientCase.billingPracticeId.toString()]
                            ?.name
                        }
                      >
                        <a
                          href={
                            "/practice/" +
                            patientCase.billingPracticeId +
                            "/view"
                          }
                          className="text-reset"
                        >
                          {
                            practices[patientCase.billingPracticeId.toString()]
                              ?.abr
                          }
                        </a>
                      </td>
                    )}
                    {token && token.role !== "Practice" && (
                      <td
                        title={
                          practices[patientCase.deliveryPracticeId.toString()]
                            ?.name
                        }
                      >
                        <a
                          href={
                            "/practice/" +
                            patientCase.deliveryPracticeId +
                            "/view"
                          }
                          className="text-reset"
                        >
                          {
                            practices[patientCase.deliveryPracticeId.toString()]
                              ?.abr
                          }
                        </a>
                      </td>
                    )}
                    {token && token.role !== "Practice" && (
                      <td>{dateToMonthAndDay(patientCase.returnBy)}</td>
                    )}
                    <td>
                      {patientCase.trackingNumber && (
                        <a
                          href={getTrackingLink(
                            patientCase.deliveryMethod,
                            patientCase.trackingNumber
                          )}
                          target="_blank"
                          rel="noreferrer"
                          className="text-reset"
                        >
                          {patientCase.trackingNumber}
                        </a>
                      )}
                    </td>
                    <td
                      className={getClassesForStatus(
                        patientCase.completionStatus
                      )}
                    >
                      {patientCase.completionStatus}
                    </td>
                    <td>{patientCase.anterior}</td>
                    <td>{patientCase.posterior}</td>
                    <td>
                      {token &&
                        token.role === "Practice" &&
                        ["Billed", "Milled", "Nested", "Shipped"].includes(
                          patientCase.completionStatus
                        ) &&
                        "$" + patientCase.cost}
                      {token &&
                        token.role !== "Practice" &&
                        "$" + patientCase.cost}
                    </td>
                    <CopyLabel 
                      patientCase={patientCase} 
                      practices={practices} 
                      dentists={dentists}
                    />
                    {token &&
                      (token.role === "Admin" ||
                        token.role === "Case Manager") && (
                      <td>
                        <a
                            title="Edit Case"
                            href={"/case/" + patientCase.id + "/edit"}
                            className="btn btn-primary m-1"
                          >
                            <span className="edit-icon">&#9998;</span>
                          </a>
                          {token && token.role === "Admin" && (
                            <button
                              type="button"
                              title="Delete Case"
                              onClick={() =>
                                deleteCase(patientCase.id.toString())
                              }
                              className="btn btn-danger m-1"
                            >
                              X
                            </button>
                          )}
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default FilterCases;
